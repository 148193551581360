import React,{Component} from "react";
import Banner from './Banner';
import About from './About';
import Services from './Services';
import DisplayCount from './DisplayCount';
// import FinancialStatus from './FinancialStatus';

import {_aboutUsList,_schemeDetails} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import {_downloadFile} from '../sub_parts/helper';

var configData = require('../../../config.js');
var downloadFilePath = configData.downloadFilePath;
var aboutImgPath = configData.aboutImgPath;

class Home extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      homeData:[],
      loadingFlag:false,
      loadingData:"loading data...",

      aboutData:'',
      image:'',

      LoanSchemeData:[],
      SavingSchemeData:[],

    }
    
  }
  
  _aboutUsList = () => {
    let that= this;
    let data = new FormData();
    data.append('specification', 'introduction');

    this.props._aboutUsList(data).then(response => { 
      that._savingSchemeDetails();
      that._schemeDetails();
      if(response.data.success){
          that.setState({aboutData:response.data.data[0].description,
            image:response.data.data[0].about_image ? aboutImgPath+response.data.data[0].about_image : '' });
        }else{
           that.setState({aboutData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingData:'Error in displaying records',aboutData:[]});
     });
  }

  _savingSchemeDetails = () => {
    let that= this;
    let data = new FormData();
    data.append('specification', 'loan_scheme');
    this.props._schemeDetails(data).then(response => { 
      if(response.data.success){
          that.setState({LoanSchemeData:response.data.data});
        }else{
           that.setState({LoanSchemeData:[]});
        }
    }).catch(function (error) {
          that.setState({LoanSchemeData:[]});
     });
  }

  _schemeDetails = () => {
    let that= this;
    let data = new FormData();
    data.append('specification', 'deposit_scheme');
    this.props._schemeDetails(data).then(response => { 
      if(response.data.success){
          that.setState({SavingSchemeData:response.data.data});
        }else{
           that.setState({SavingSchemeData:[]});
        }
    }).catch(function (error) {
          that.setState({SavingSchemeData:[]});
     });
  }

  
  componentDidMount(){
    this._aboutUsList();
  }

  callback = (homeData) =>{
    this.setState({homeData:homeData})
  }


render(){
  const { homeData,aboutData,image,SavingSchemeData,LoanSchemeData } = this.state;

  return (
      <React.Fragment>
          <Banner callback={this.callback}/>
          <About aboutData={aboutData} image={image} />
              <Services SavingSchemeData={SavingSchemeData} LoanSchemeData={LoanSchemeData} />
          <DisplayCount homeData={homeData}/>
          {/* <FinancialStatus /> */}
      </React.Fragment>
    );  
  }
}

  
Home.propTypes = {
  _aboutUsList:PropTypes.func.isRequired,
  _schemeDetails:PropTypes.func.isRequired,
}

export default withRouter(connect(null, {_aboutUsList,_schemeDetails})(Home));
  