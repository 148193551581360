var axios = require('axios');

// var ApiPath ='http://localhost:4400/';

var ApiPath ='http://shreeambikapatsanstha.com/api/api/';
var memberImgPath = 'http://shreeambikapatsanstha.com/api/uploads/member_photo/';
var userImgPath = 'http://shreeambikapatsanstha.com/api/uploads/user_photo/';
var branchLocation = 'http://shreeambikapatsanstha.com/api/uploads/location_photo/';
var reportFilePath = 'http://shreeambikapatsanstha.com/api/uploads/annual_report/';
var galleryPath = 'http://shreeambikapatsanstha.com/api/uploads/gallery/';
var downloadFilePath = 'http://shreeambikapatsanstha.com/api/uploads/download_file/';
var servicePath = 'http://shreeambikapatsanstha.com/api/uploads/service_photo/';
var contatcUsPath = 'http://shreeambikapatsanstha.com/api/uploads/contact_logo/';
var generalPath = 'http://shreeambikapatsanstha.com/api/uploads/general_logo/';
var sliderPath = 'http://shreeambikapatsanstha.com/api/uploads/slider_images/';
var newsPhotoPath = 'http://shreeambikapatsanstha.com/api/uploads/news_photo/';
var aboutImgPath = 'http://shreeambikapatsanstha.com/api/uploads/about_image/';
var schemeImage = 'http://shreeambikapatsanstha.com/api/uploads/scheme_image/';
var defaultImg = 'http://54.215.251.193/dcs_api/uploads/ProfilePic/user.png';
var interestFilePath = 'http://shreeambikapatsanstha.com/api/uploads/interest_file/';


var Api = axios.create({
  baseURL: ApiPath,
  timeout: 100000,
  headers: {'Content-Type': 'application/x-www-form-urlencoded'},
});

module.exports={ Api,memberImgPath,defaultImg,userImgPath,branchLocation,reportFilePath,galleryPath,downloadFilePath,servicePath,contatcUsPath,generalPath,sliderPath,newsPhotoPath,aboutImgPath,schemeImage,interestFilePath }