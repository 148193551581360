import React,{Component} from "react";

import {_postInformation} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

class FeedbackForm extends Component {
  constructor(props) {
    super(props);  
    this.state = {
        errorMsg:null,
        errorClass:'',

        member_name:'',
        email:'',
        state:'',
        city:'',
        address:'',
        message:'',

        member_nameError:'',
        emailError:'',
        stateError:'',
        cityError:'',
        addressError:'',
        messageError:'',
    }
  }

  _onlyNumber = (e) =>{
    const re = /[0-9]+/g;
        if (!re.test(e.key)) {
        e.preventDefault();
      }
  }

  _handleValidation(evt){
   var member_nameError = '';
   var emailError = '';
   var stateError = '';
   var cityError = '';
   var addressError = '';
   var messageError = '';

    var reWhiteSpace = new RegExp(/^\s+$/);
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.member_name == '' || this.state.member_name == null) {
      member_nameError = 'Enter your name';
    }
    if (this.state.email === '' || re.test(this.state.email) === false) {
        emailError = 'Enter valid email';
    }
    if (this.state.state === '' || reWhiteSpace.test(this.state.state) === true) {
        stateError = 'Enter state name';
    }
    if (this.state.city === '' || reWhiteSpace.test(this.state.city) === true) {
      cityError = 'Enter city name';
   }
   if (this.state.address === '' || reWhiteSpace.test(this.state.address) === true) {
    addressError = 'Enter your address';
    }
    if (this.state.message === '' || reWhiteSpace.test(this.state.message) === true) {
        messageError = 'Enter your message';
    }

    if (member_nameError || emailError || messageError || stateError || cityError || addressError !== '' ){
  
      this.setState({ member_nameError : member_nameError,
                      emailError : emailError,
                      stateError : stateError,
                      cityError : cityError,
                      addressError : addressError,
                      messageError : messageError });
    }else{
      this.setState({ member_nameError : '',
                      emailError : '',
                      stateError : '',
                      messageError : '',
                      cityError:'',
                      addressError:'' });
                      
              this._handleApply(evt);
      }
  }

  _handleChange(evt){
    this.setState({ [evt.target.name]: evt.target.value });
  }

_handleApply = (evt) =>{
    var that  = this;
    let data = new FormData();

    that.setState({loadingFlag:true });

    data.append('name', this.state.name);
    data.append('email', this.state.email);
    data.append('city', this.state.city);
    data.append('state', this.state.state);
    data.append('address', this.state.address);
    data.append('message', this.state.message);

    // this.props._postInformation(data).then(response => { 
    //   if(response.data.success){
    that.setState({loadingFlag:false,errorMsg:'Form submitted successfully',errorClass:'alert alert-success' });
    //   }else{
    // that.setState({loadingFlag:false,errorMsg:'Error in form submission',errorClass:'alert alert-danger' });
    //   }
    // }).catch(function (error) {      
    //   throw error;
    // });
}

render(){
  
  return (
          <div className="col-md-6">
            <div className="form-block">
              <h4>तक्रार / अभिप्राय</h4>
              {this.state.errorMsg &&
                    <div class={this.state.errorClass} role="alert">
                        {this.state.errorMsg}
                    </div>}
  
                <div className="form-group">
                    <label>नाव</label>
                    <input type="text" 
                          name="member_name" 
                          value={this.state.member_name}
                          className="form-control" 
                          onChange={ (evt) => this._handleChange(evt) }
                          placeholder="Enter name" />
                             {this.state.member_nameError && 
                            <span className="text-danger"> {this.state.member_nameError} </span>}
                </div>
  
                <div className="form-group">
                    <label>ई-मेल आयडी</label>
                    <input type="text" 
                           name="email" 
                           value={this.state.email}
                           className="form-control" 
                           onChange={ (evt) => this._handleChange(evt) }
                           placeholder="Enter valid email address" />
                              {this.state.emailError && 
                            <span className="text-danger"> {this.state.emailError} </span>}
                    </div>
  
                    <div className="form-group">
                    <label>शहर</label>
                    <input type="text" 
                           name="city" 
                           className="form-control" 
                           value={this.state.city}
                           onChange={ (evt) => this._handleChange(evt) }
                           placeholder="Enter city" />
                              {this.state.cityError && 
                            <span className="text-danger"> {this.state.cityError} </span>}
                    </div>
  
                    <div className="form-group">
                    <label>राज्य</label>
                    <input type="text" 
                           name="state" 
                           className="form-control" 
                           value={this.state.state}
                           onChange={ (evt) => this._handleChange(evt) }
                           placeholder="Enter state" />
                              {this.state.stateError && 
                            <span className="text-danger"> {this.state.stateError} </span>}
                    </div>
                    <div className="form-group">
                    <label>पत्ता</label>
                        <textarea rows="3"
                                  className="form-control"
                                  name="address"
                                  onChange={(evt)=>this._handleChange(evt)}
                                  value={this.state.address}
                                   placeholder="enter address"></textarea>
                                    {this.state.addressError  && 
                            <span className="text-danger"> {this.state.addressError } </span>}
                    </div>
                    <div className="form-group">
                    <label>तक्रार / अभिप्राय तपशिल</label>
                        <textarea rows="3"
                                  className="form-control"
                                  name="message"
                                  onChange={(evt)=>this._handleChange(evt)}
                                  value={this.state.message}
                                   placeholder="state"></textarea>
                                    {this.state.messageError  && 
                            <span className="text-danger"> {this.state.messageError } </span>}
                    </div>
  
                <div className="form-btnBlock">
                 <button class="btnApply" disabled={this.state.loadingFlag} onClick={(evt)=>this._handleValidation(evt)}>
                    {this.state.loadingFlag && <i class="fa fa-refresh fa-spin"></i>} &nbsp;पाठवा
                </button>
                </div>
  
            </div>
          </div>
    );  
  }
}
  
FeedbackForm.propTypes = {
  _postInformation: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_postInformation})(FeedbackForm));