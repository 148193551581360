import React,{Component} from "react";
import {_serviceData} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

var configData = require('../../../config.js');
var servicePath = configData.servicePath;

class Service extends Component {
  constructor(props) {
    super(props);  
    this.state = {
        serviceData:[],
        loadingFlag:false,
        loadingData:"loading data...",
    }
    
  }

  _serviceData = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._serviceData().then(response => { 
        if(response.data.success){
          that.setState({loadingFlag:false,serviceData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,serviceData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',serviceData:[]});
     });
  }

  componentDidMount(){
    this._serviceData();
  }

render(){
    const {serviceData} = this.state;

  return (
    <div className="seva-section">
    <div className="title-block">
        <h1>सेवा</h1>
    </div>
    <div className="container">
        <div className="seva-content">
            <h4>अर्थ वृद्धी सोबतच ....... मोबाईल बँकींग सेवा</h4>

            <div className="row">
                <div className="col-md-12">
                {serviceData && serviceData.length > 0 && serviceData.map((service,index)=>(
                    <div className="seva-data" key={index}>
                        <h5>{++index} ) {service.service_title ? service.service_title : ''} :</h5>
                        <div className="row">
                        <div className="col-md-3">
                            <img src={servicePath+service.service_photo} className="img-fluid d-block" />
                        </div>
                      <div className="col-md-9">
                        <p dangerouslySetInnerHTML={{__html:service.description ? service.description : ''}} />
                        </div>
                        </div>
                    </div>))}    

                    {this.state.loadingFlag &&
                    <div className="row loadingData">
                    <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
                    <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
                    </div>}
                </div>
            </div>
        </div>
    </div>	
</div>
    );  
  }
}

Service.propTypes = {
    _serviceData: PropTypes.func.isRequired
  }
  
  export default withRouter(connect(null, {_serviceData})(Service));
