import React,{Component} from "react";
import {_financialStatusList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

class FinancialStatus extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      financeData:[],
      loadingFlag:false,
      loadingData:"loading data...",
      showItems:5,
    }
  }

  _financialStatusList = () => {
    let that= this;
    this.setState({loadingFlag:true});
    this.props._financialStatusList().then(response => { 
      if(response.data.success){
        console.log(response.data.data)
          that.setState({loadingFlag:false,loadingData:'',financeData:response.data.data });
        }else{
           that.setState({loadingFlag:false,loadingData:'No records found!',financeData:[] });
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingData:'Error in displaying records',financeData:[],loadingFlag:false});
     });
  }

  componentDidMount(){
    this._financialStatusList();
  }

render(){
  const {financeData,showItems} = this.state;
  
  return (
   
    <div className="bank-numbers">
      	<div className="container">

                     {this.state.loadingFlag &&
                        <div className="row loadingData">
                        <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
                        <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
                        </div>}
                <ul>

                  <li className="list-heading">
                    <h6>
                    आर्थिक स्थिती </h6>
                  </li>

               
                  <li className="even">
                    <div className="row">
                      <div className="col-md-2 col-2 thev-info">
                        <p style={{fontWeight: 'bold'}}>वर्ष</p>
                      </div>
                      {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index1)=>(
                      <div className="col-md-2 col-2 thev-info" key={index1}>
                        <p>{finance.year}</p>
                      </div>))}

                    </div>
                  </li>
                  
                  <li className="odd">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>सभासद संख्या</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index2)=>(
                    <div className="col-md-2 col-2 thev-info" key={index2}>
                      <p>{finance.council_number}</p>
                      </div>))}
                  </div>
                </li>

                <li className="even">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>वसूल भागभांडवल</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index3)=>(
                    <div className="col-md-2 col-2 thev-info" key={index3}>
                      <p>{finance.recovered_share_capital}</p>
                      </div>))}
                  </div>
                </li>


                <li className="odd">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>निधी</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index4)=>(
                    <div className="col-md-2 col-2 thev-info" key={index4}>
                      <p>{finance.funding}</p>
                      </div>))} 
                  </div>
                </li>

                <li className="even">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>ठेवी</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index5)=>(
                    <div className="col-md-2 col-2 thev-info" key={index5}>
                      <p>{finance.deposits}</p>
                      </div>))} 
                  </div>
                </li>

                 <li className="odd">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>कर्ज</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index6)=>(
                    <div className="col-md-2 col-2 thev-info" key={index6}>
                      <p>{finance.loan}</p>
                      </div>))} 
                  </div>
                </li>

                <li className="even">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>गुंतवणूक</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index7)=>(
                    <div className="col-md-2 col-2 thev-info" key={index7}>
                      <p>{finance.investment}</p>
                      </div>))} 
                  </div>
                </li>



                <li className="odd">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>नफा</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index8)=>(
                    <div className="col-md-2 col-2 thev-info" key={index8}>
                      <p>{finance.profit}</p>
                      </div>))} 
                   </div>
                </li>

                <li className="even">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>खेळते भांडवल</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index9)=>(
                    <div className="col-md-2 col-2 thev-info" key={index9}>
                      <p>{finance.playing_capital}</p>
                      </div>))} 
                   </div>
                </li>


                <li className="odd">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>थकबाकी प्रमाण</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index10)=>(
                    <div className="col-md-2 col-2 thev-info" key={index10}>
                      <p>{finance.outstanding_ratio}</p>
                      </div>))} 
                   </div>
                </li>

                <li className="even">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>ऑडिट वर्ग</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index11)=>(
                    <div className="col-md-2 col-2 thev-info" key={index11}>
                      <p>"{finance.audit_class}"</p>
                      </div>))} 
                   </div>
                </li>

                <li className="odd">
                  <div className="row">
                    <div className="col-md-2 col-2 thev-info">
                      <p style={{fontWeight: 'bold'}}>लाभांश</p>
                    </div>
                    {financeData && financeData.length > 0 && financeData.slice(0,showItems).map((finance,index12)=>(
                    <div className="col-md-2 col-2 thev-info" key={index12}>
                      <p>{finance.dividends}%</p>
                      </div>))} 
                   </div>
                </li>
                </ul>
              </div>
    </div>
    );  
  }
}
  
FinancialStatus.propTypes = {
  _financialStatusList: PropTypes.func.isRequired,
}

export default withRouter(connect(null, {_financialStatusList})(FinancialStatus));
  

