import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
export default function LoadingPreivew(props){
        return(
            <div className="sweet-loading">
                  <ClipLoader
                    size={50}
                    color={"#123abc"}
                    loading={props.loading} />
                </div>
        );
}

