var configData = require('../config.js');
var Api = configData.Api;

export function _branchDetails() {  
	return dispatch => {
        return Api.get('/dashboard/branchList');
	}
  }

  export function _aboutUsList(data) {  
	return dispatch => {
	  return Api.post('/dashboard/aboutUsList',data);
	}
  }

  export function _memberList(data) {  
	return dispatch => {
	  return Api.post('/dashboard/memberList',data);
	}
  }

  export function _committeeList() {  
	return dispatch => {
	  return Api.get('/dashboard/committeeList');
	}
  }

  export function _branchMemberList() {  
	return dispatch => {
	  return Api.get('/dashboard/branchMemberList');
	}
  }

  export function _getAnnualReport() {  
	return dispatch => {
	  return Api.get('/dashboard/annualReportList');
	}
  }


  export function _downloadList() {  
	return dispatch => {
	  return Api.get('/dashboard/downloadList');
	}
  }

  export function _galleryList() {  
	return dispatch => {
	  return Api.get('/dashboard/galleryList');
	}
  }

  export function _getCareerList() {  
	return dispatch => {
	  return Api.get('/dashboard/careerList');
	}
  }

  export function _postCareerData(data) {  
	return dispatch => {
	  return Api.post('/dashboard/demo',data);
	}
  }

  export function _postInformation(data) {  
	return dispatch => {
	  return Api.post('/dashboard/demo',data);
	}
  }
  
  export function _homeData() {  
	return dispatch => {
	  return Api.get('/dashboard/generalList');
	}
  }
  
  export function _contactData() {  
	return dispatch => {
	  return Api.get('/dashboard/contactList');
	}
  }
  
  export function _programmeList() {  
	return dispatch => {
	  return Api.get('/dashboard/programmeList');
	}
  }

  export function _getOfficerList() {  
	return dispatch => {
	  return Api.get('/dashboard/officerList');
	}
  }

  export function _serviceData() {  
	return dispatch => {
	  return Api.get('/dashboard/serviceList');
	}
  }

  export function _schemeDetails(data) {  
	return dispatch => {
	  return Api.post('/dashboard/planList',data);
	}
  }
  export function _financialStatusList() {  
	return dispatch => {
	  return Api.get('/dashboard/financialStatusList');
	}
  }

  export function _interestList() {  
	return dispatch => {
	  return Api.get('/dashboard/interestList');
	}
  }