import React,{Component} from "react";

import {_postInformation} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

class InformationForm extends Component {
  constructor(props) {
    super(props);  
    this.state = {
        errorMsg:null,
        errorClass:'',

        member_name:'',
        email:'',
        mobile:'',
        message:'',

        member_nameError:'',
        emailError:'',
        mobileError:'',
        messageError:'',
    }
  }

  _onlyNumber = (e) =>{
    const re = /[0-9]+/g;
        if (!re.test(e.key)) {
        e.preventDefault();
      }
  }

  _handleValidation(evt){
   var member_nameError = '';
   var emailError = '';
   var mobileError = '';
   var messageError = '';

    var reWhiteSpace = new RegExp(/^\s+$/);
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.member_name == '' || this.state.member_name == null) {
      member_nameError = 'Enter your name';
    }
    if (this.state.email === '' || re.test(this.state.email) === false) {
        emailError = 'Enter valid email';
    }
    if (this.state.mobile === '' || reWhiteSpace.test(this.state.mobile) === true) {
        mobileError = 'Enter 10 digit mobile number';
    }
    if (this.state.message === '' || reWhiteSpace.test(this.state.message) === true) {
        messageError = 'Enter your message';
    }

    if (member_nameError || emailError || mobileError || messageError !== '' ){
  
      this.setState({ member_nameError : member_nameError,
                      emailError : emailError,
                      mobileError : mobileError,
                      messageError : messageError });
    }else{
      this.setState({ member_nameError : '',
                      emailError : '',
                      mobileError : '',
                      messageError : '' });
              this._handleApply(evt);
      }
  }

  _handleChange(evt){
    this.setState({ [evt.target.name]: evt.target.value });
  }

_handleApply = (evt) =>{
    var that  = this;
    let data = new FormData();

    that.setState({loadingFlag:true });

    data.append('name', this.state.name);
    data.append('email', this.state.email);
    data.append('mobile', this.state.mobile);
    data.append('message', this.state.message);

    // this.props._postInformation(data).then(response => { 
    //   if(response.data.success){
    that.setState({loadingFlag:false,errorMsg:'Form submitted successfully',errorClass:'alert alert-success' });
    //   }else{
    // that.setState({loadingFlag:false,errorMsg:'Error in form submission',errorClass:'alert alert-danger' });
    //   }
    // }).catch(function (error) {      
    //   throw error;
    // });
}

render(){
  
  return (
          <div className="col-md-6">
            <div className="form-block">
              <h4>माहितीसाठी</h4>
              {this.state.errorMsg &&
                    <div className={this.state.errorClass} role="alert">
                        {this.state.errorMsg}
                    </div>}
  
                <div className="form-group">
                    <label>सभासदाचे नाव</label>
                    <input type="text" 
                          name="member_name" 
                          value={this.state.member_name}
                          className="form-control" 
                          onChange={ (evt) => this._handleChange(evt) }
                          placeholder="Enter member name" />
                             {this.state.member_nameError && 
                            <span className="text-danger"> {this.state.member_nameError} </span>}
                </div>
  
                <div className="form-group">
                    <label>ई-मेल पत्ता</label>
                    <input type="text" 
                           name="email" 
                           value={this.state.email}
                           className="form-control" 
                           onChange={ (evt) => this._handleChange(evt) }
                           placeholder="Enter Your Email Address" />
                              {this.state.emailError && 
                            <span className="text-danger"> {this.state.emailError} </span>}
                    </div>
  
                    <div className="form-group">
                    <label>दुरध्वनी /भ्रमणध्वनी क्रं.</label>
                    <input type="text" 
                           name="mobile" 
                           maxLength="10"
                           className="form-control" 
                           value={this.state.mobile}
                           onChange={ (evt) => this._handleChange(evt) }
                           onKeyPress={(evt)=>this._onlyNumber(evt)}
                           placeholder="Enter Your Mobile Number" />
                              {this.state.mobileError && 
                            <span className="text-danger"> {this.state.mobileError} </span>}
                    </div>
  
                    <div className="form-group">
                    <label>संदेश तपशिल</label>
                        <textarea rows="3"
                                  className="form-control"
                                  name="message"
                                  onChange={(evt)=>this._handleChange(evt)}
                                  value={this.state.message}
                                   placeholder="Message"></textarea>
                                    {this.state.messageError  && 
                            <span className="text-danger"> {this.state.messageError } </span>}
                    
                    </div>
  
                <div className="form-btnBlock">
                 <button className="btnApply" disabled={this.state.loadingFlag} onClick={(evt)=>this._handleValidation(evt)}>
                    {this.state.loadingFlag && <i className="fa fa-refresh fa-spin"></i>} &nbsp;पाठवा
                </button>
                </div>
  
            </div>
          </div>
    );  
  }
}
  
InformationForm.propTypes = {
  _postInformation: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_postInformation})(InformationForm));