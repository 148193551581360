import React,{Component} from "react";
// import owlJS from '../../assets/js/owl.carousel.js';
import $ from 'jquery'; 

import {_homeData } from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';

var configData = require('../../../config.js');
var sliderPath = configData.sliderPath;

class Banner extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      homeData:[],
    }
    
  }

  _homeData = () => {
    let that= this;
    this.props._homeData().then(response => { 
        if(response.data.success){
          that.setState({homeData:response.data.data[0]});
          that.props.callback(response.data.data[0]);
        }else{
           that.setState({homeData:[]});
        }
    }).catch(function (error) {
      console.log(error)
     });
  }

  componentDidMount () {
      this._homeData();
}

render(){

  console.log(this.state.homeData);


const {homeData } = this.state;
let slider_1 = homeData.slider_image1 ? sliderPath+homeData.slider_image1 : '';
let slider_2 = homeData.slider_image2 ? sliderPath+homeData.slider_image2 : '';
let slider_3 = homeData.slider_image3 ? sliderPath+homeData.slider_image3 : '';
let slider_4 = homeData.slider_image4 ? sliderPath+homeData.slider_image4 : '';

  return (
    <div className="banner">
    <div id="banner-demo" className="owl-carousel owl-theme">
        <div className="item">
            <img src={slider_1} alt="Owl Image" />
        </div>

        <div className="item">
            <img src={slider_2} alt="Owl Image" />
        </div>


        <div className="item">
            <img src={slider_3} alt="Owl Image" />
        </div>

        <div className="item">
            <img src={slider_4} alt="Owl Image" />
        </div>
    </div>
</div>
    );  
  }
}
  
  
Banner.propTypes = {
  _homeData: PropTypes.func.isRequired,
}

export default withRouter(connect(null, {_homeData})(Banner));
  