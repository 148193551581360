import React,{Component} from "react";
import { Link } from "react-router-dom";

import logo from '../../assets/images/header.jpg'

const google = window.google;


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language:'Marathi'
    };
  }
  

onSiteChanged = (e) =>{  
  if(e.target.value == 'Marathi'){
    window.changeLanguageByButtonClick('mr')
  }
  if(e.target.value == 'English'){
    window.changeLanguageByButtonClick('en')
  }
  this.setState({language: e.target.value});
}

_goHome = () =>{
window.location.href = '/';
}

render(){

  return (
    <React.Fragment>

<div class="header">
    	<img src={logo} class="headerimg img-fluid d-block" />


      <div class="language-section">

    		<span>भाषा बदल:&nbsp;</span>
    		<div class="form-check-inline language-radio">
    			<label class="form-check-label">
           <input type="radio" 
                  class="form-check-input" 
                  name="site_name" 
                  value="Marathi" 
                  checked={this.state.language == 'Marathi'} 
                  onChange={this.onSiteChanged} />
                  मराठी
    			</label>
    		</div>

    		<div class="form-check-inline language-radio">
        <label class="form-check-label">
           <input type="radio" 
                  class="form-check-input" 
                  name="site_name" 
                  value="English" 
                  checked={this.state.language == 'English'} 
                  onChange={this.onSiteChanged} />
                  इंग्रजी
    			</label>
    		</div>



    	</div>

    </div>

<div className="main-header">
<nav className="navbar navbar-default navbar-expand-md">
<button className="navbar-toggler custom-toggler" data-toggle="collapse" data-target="#collapsibleNavbarOne" aria-expanded="false">
  <span className="navbar-toggler-icon"></span>
</button>

<div className="collapse navbar-collapse menu-nav menu" aria-expanded="false" id="collapsibleNavbarOne">

  <ul className="nav navbar-nav mx-auto">

    <li className="nav-item" id="active">
      <a className="nav-link js-scroll-trigger active" style={{cursor:'pointer'}} href="http://shreeambikapatsanstha.com/ambika_ui/home">मुख्य पान</a>
    </li>

    <li className="nav-item dropdown header-dropdown">
      <a className="nav-link js-scroll-trigger dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">संस्था परिचय</a>
      <div className="dropdown-menu">
        <Link className="dropdown-item dropdown-content" to={'./about-us'}>परिचय</Link>
        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./vision'}>संस्था उद्दिष्ट</Link>
        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./occult'}>अध्यक्षीय मनोगत </Link>
        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./officer-list'}>अधिकारी वर्ग</Link>
        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./financial-condition'}>आर्थिक स्थिती </Link>
      </div>
    </li>


    <li className="nav-item dropdown header-dropdown">
      <a className="nav-link js-scroll-trigger dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">संघटना</a>
      <div className="dropdown-menu">
      <Link className="dropdown-item dropdown-content" to={'./founder-director'}>संस्थापक</Link>

        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./board-of-director'}>संचालक मंडळ</Link>

        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./committee'}>समिती</Link>

        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./branch-consultant-committee'}>शाखा सल्लागार समिती</Link>


      </div>
    </li>

    

    
    <li className="nav-item dropdown header-dropdown">
      <a className="nav-link js-scroll-trigger dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">योजना</a>
      <div className="dropdown-menu">
        <Link className="dropdown-item dropdown-content" to={'./deposit-scheme'}>ठेव योजना</Link>
        <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./loan-scheme'}>कर्ज योजना</Link>     
        {/* <div className="dropdown-divider"></div>
        <Link className="dropdown-item dropdown-content" to={'./interest-rate'}>व्याजदर </Link>    */}

      </div>
    </li>

      <li class="nav-item" id="active">
        <Link to={'./service'} className="nav-link js-scroll-trigger">सेवा</Link> 
      </li>

    <li className="nav-item" id="active">
      <Link to={'./annual-report'} className="nav-link js-scroll-trigger">वार्षिक अहवाल</Link> 
    </li>

    <li className="nav-item" id="active">
    <Link to={'./branch'} className="nav-link js-scroll-trigger">शाखा</Link> 
       </li>

    <li className="nav-item" id="active">
      <Link to={'./interest-rate'} className="nav-link js-scroll-trigger">व्याजदर</Link> 
    </li>

    <li className="nav-item" id="active">
      <Link to={'./download'} className="nav-link js-scroll-trigger">डाउनलोड</Link>
    </li>

      <li className="nav-item" id="active">
        <Link to={'./career'} className="nav-link js-scroll-trigger">कारकीर्द</Link>    
       </li>

    <li className="nav-item" id="active">
      <Link to={'./news'} className="nav-link js-scroll-trigger">बातम्या व कार्यक्रम</Link> 
    </li>

    <li className="nav-item" id="active">
    <Link to={'./contact'} className="nav-link js-scroll-trigger">संपर्क</Link> 
     </li>


  </ul>

</div>
</nav>
</div>
</React.Fragment>

    );  
  }
}

export default Header;