import React,{Component} from "react";
import { Link } from "react-router-dom";

class About extends Component {
  constructor(props) {
    super(props);  
    
  }

render(){
	const {aboutData,image} = this.props;
  return (
    <div className="aboutUs">
	<div className="container">
		{/* <div className="about-data">

			<div className="abt-text wow zoomIn" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'zoomIn'}}>
						<h4>Who We Are</h4>

						<div className="row">
							<div className="col-md-7">
								<div dangerouslySetInnerHTML={{__html:aboutData.substring(0,400)+'...'}} />
							</div>
							<div className="col-md-5">
								<img src={image} className="set_about_img img-fluid"/>
							</div>
						
						</div>

						<div className="readMore">
							<Link to={'./about-us'}>Read More</Link>
						</div>
					</div>
		    </div> */}


			<div className="about-data">
			<div className="abt-text wow zoomIn" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'zoomIn'}}>
						<h4>आमच्या विषयी</h4>
						<div dangerouslySetInnerHTML={{__html:aboutData.substring(0,400)+'...'}} />

						<div className="readMore">
						<Link to={'./about-us'}>अधिक पहा</Link>
						</div>
					</div>

		</div>

	     </div>
        </div>
    );  
  }
}
  
  export default About;