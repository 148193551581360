import React,{Component} from "react";
import {_programmeList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import {_formatDate} from '../sub_parts/helper';

var configData = require('../../../config.js');
var newsPhotoPath = configData.newsPhotoPath;
var defaultImg = configData.defaultImg;

var configData = require('../../../config.js');
var galleryPath = configData.galleryPath;

class NewsProgram extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      newsData:[],
      loadingFlag:false,
      loadingData:"loading data...",

    }
  }

  _programmeList = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._programmeList().then(response => { 
        if(response.data.success){
          console.log(response.data.data);
          console.log('yessssssssss')
          that.setState({loadingFlag:false,newsData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,newsData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',newsData:[]});
     });
  }

  componentDidMount(){
    this._programmeList();
  }

render(){
  const { newsData } = this.state;

  return (
<React.Fragment>
    <div className="news-section">
  <div className="container">
    <div className="news-block">
      <div className="row">
   {newsData && newsData.length > 0 && newsData.map((news,index)=>(

        <div className="col-md-4" key={index}>
          <div className="news-box">
          <div className="news-img">
              <img src={news.news_photo ? newsPhotoPath+news.news_photo : defaultImg} className="img-fluid d-block" />
          </div>
           <div className="news-data">
            <h3><a href="#">{news.title ? news.title : ''}</a></h3> 
                <h6>Date:{" "}{_formatDate(news.date)}</h6>
          </div>
        </div>
        </div>))}

      </div>

      {this.state.loadingFlag &&
        <div className="row loadingData">
        <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
        <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
        </div>}

    </div>
  </div>
</div>


{/* <div className="title-block">
  <h1>Events</h1>
</div>
<div className="event-section">
 
  <div className="container">

    <div id="event-demo" className="owl-carousel owl-theme">
   
    <div className="item">
     <div className="event-box">
          <div className="event-img">
            <img src="../../assets/images/event1.jpg" className="img-fluid d-block" />
          </div>
           <div className="event-data">
            <span>May 16, 2020</span>
              <h3><a href="#">Sangta Sohla</a></h3>
            </div>
        </div>
  </div>

    <div className="item">
     <div className="event-box">
          <div className="event-img">
            <img src="../../assets/images/event2.jpg" className="img-fluid d-block" />
          </div>
         <div className="event-data">
            <span>May 16, 2020</span>
              <h3><a href="#">Sangta Sohla</a></h3>
            </div>
        </div>
  </div>


 <div className="item">
     <div className="event-box">
          <div className="event-img">
            <img src="../../assets/images/events3.jpg" className="img-fluid d-block" />
          </div>
          <div className="event-data">
            <span>May 16, 2020</span>
              <h3><a href="#">Sangta Sohla</a></h3>
            </div>
        </div>
  </div>


 <div className="item">
     <div className="event-box">
          <div className="event-img">
            <img src="../../assets/images/events4.jpg" className="img-fluid d-block" />
          </div>
          <div className="event-data">
            <span>May 16, 2020</span>
              <h3><a href="#">Sangta Sohla</a></h3>
            </div>
        </div>
  </div>


 <div className="item">
     <div className="event-box">
          <div className="event-img">
            <img src="../../assets/images/events5.jpg" className="img-fluid d-block" />
          </div>
          <div className="event-data">
            <span>May 16, 2020</span>
              <h3><a href="#">Sangta Sohla</a></h3>
            </div>
        </div>
  </div>


 <div className="item">
     <div className="event-box">
          <div className="event-img">
            <img src="../../assets/images/logo.png" className="img-fluid d-block" />
          </div>
          <div className="event-data">
            <span>May 16, 2020</span>
              <h3><a href="#">Sangta Sohla</a></h3>
            </div>
        </div>
  </div>


</div>
  </div>
</div> */}

</React.Fragment>

    );  
  }
}
  
NewsProgram.propTypes = {
  _programmeList: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_programmeList})(NewsProgram));