import React,{Component} from "react";
import {_getAnnualReport} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import {_downloadFile} from '../sub_parts/helper';

var configData = require('../../../config.js');
var reportFilePath = configData.reportFilePath;


class AnnualReport extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      annualReportList:[],
      loadingFlag:false,
      loadingData:"loading data..."

    }
  }

  _getAnnualReport = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._getAnnualReport().then(response => { 
        if(response.data.success){
          that.setState({loadingFlag:false,annualReportList:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,annualReportList:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',annualReportList:[]});
     });
  }

  componentDidMount(){
    this._getAnnualReport();
  }

  _download = (evt,file) =>{
    if(file){
    var file_path = reportFilePath+file;
     _downloadFile(file_path);
    }
  }

render(){
  const { annualReportList } = this.state;

  return (
    <div className="annualReport-section">
     <div className="title-block">
    <h1>अहवाल</h1>
    </div>
    <div className="container">
      <div className="annualReport-block">
      
      {annualReportList && annualReportList.length > 0 && annualReportList.map((report,index)=>(
          <div className="annualReport-box" key={index}>
             <div className="row">
                <div className="col-md-9">
                <div className="annualReport-content">
                  <h5>{report.report_title ? report.report_title : ''}</h5>
                </div>
              </div>

              <div className="col-md-3">
                <div className="annualReport-btn">
                  <a href="javascript:void(0);" className="pdf-btn" onClick={(evt)=>this._download(evt,report.report_file)}>
                    <i className="fa fa-download" aria-hidden="true">
                  </i>&nbsp;&nbsp;Download</a>
                </div>
              </div>
            </div>
          </div>))}    

     {this.state.loadingFlag &&
        <div className="row loadingData">
        <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
        <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
        </div>}
      </div>
    </div>
  </div>
    );  
  }
}
  
AnnualReport.propTypes = {
  _getAnnualReport: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_getAnnualReport})(AnnualReport));