import React,{Component} from "react";

class DisplayCount extends Component {
  constructor(props) {
    super(props);  
    
  }

render(){
	const { homeData } = this.props;

  return (
    <div className="count">
		<div className="container">
			<div className="count-data">
				<div className="row">

					<div className="col-md-3">
						<div className="count-text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
							<img src="../../assets/images/calendar.png" className="img-fluid" />
							<div className="count-info">
								<h3>सेवेची वर्ष</h3>
								<h4>{homeData.year_of_service ? homeData.year_of_service : ''}</h4>
							</div>
						</div>
					</div>

					
					<div className="col-md-3">
						<div className="count-text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
							<img src="../../assets/images/group.png" className="img-fluid" />
							<div className="count-info">
								<h3>एकूण सदस्य</h3>
								<h4>{homeData.total_members ? homeData.total_members : ''}</h4>
							</div>
						</div>
					</div>

					<div className="col-md-3">
						<div className="count-text wow fadeInRight" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInRight'}}>
							<img src="../../assets/images/profit.png" className="img-fluid" />
							<div className="count-info">
								<h3>उलाढाल</h3>
								<h4>{homeData.total_turnover ? homeData.total_turnover : ''}</h4>
							</div>
						</div>
					</div>

					<div className="col-md-3">
						<div className="count-text wow fadeInRight" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInRight'}}>
							<img src="../../assets/images/location.png" className="img-fluid" />
							<div className="count-info">
								<h3>एकूण शाखा</h3>
								<h4>{homeData.total_branches ? homeData.total_branches : ''}</h4>
							</div>
						</div>
					</div>



				</div>
			</div>
		</div>
	</div>
    );  
  }
}
  
  export default DisplayCount;