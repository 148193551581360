import React,{Component} from "react";
import {_branchDetails} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

var configData = require('../../../config.js');
var branchLocation = configData.branchLocation;

class Branch extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      branchData:[],
      loadingFlag:false,
      loadingData:'',
    }
  }

  _branchDetails = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._branchDetails().then(response => { 
      if(response.data.success){
        console.log('XXXXXXXXXXXXXXXXXXXXXXXX');
        console.log(response.data.data);

          that.setState({loadingFlag:false,branchData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,branchData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',branchData:[]});
     });
  }

  componentDidMount(){
    this._branchDetails();
  }

render(){
  const {branchData} = this.state;

  return (
    <div className="branch">
    <div className="title-block">
      <h1>शाखा</h1>
    </div>
    <div className="container">
      <div className="branch-content">
        <div className="row">
  
         {branchData && branchData.length > 0 && branchData.map((branch,index)=>(
            <div className="col-md-4" key={index}>
            <div className="branch-data wow zoomIn" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'zoomIn'}}>
              <div className="branch-text">
                <h3>{branch.branch_name} - {branch.zone_name}</h3>
              </div>

              <div className="branch-address">
              <div className="col-md-12">
                    <img src={branchLocation+branch.location_photo} className="img-fluid d-block" />
                </div>

                  <p>{branch.address ? branch.address : ''}<br />
                  {branch.district ? branch.district : ''}, {branch.state ? branch.state : ''}, {branch.pin ? branch.pin : ''}<br />
                  Telephone: {branch.mobile_no ? branch.mobile_no : ''}
                </p>
              <p dangerouslySetInnerHTML={{__html:branch.timing}} />

             </div>
  
             {/* <div className="branch-btn">
              <a href="#">Map</a>
            </div> */}
          </div>
        </div>))}
        </div>
        {this.state.loadingFlag &&
        <div className="row loadingData">
          <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
          <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
        </div>}

      </div>
    </div>
  </div>
    );  
  }
}
  
  Branch.propTypes = {
    _branchDetails: PropTypes.func.isRequired
  }
  
  export default withRouter(connect(null, {_branchDetails})(Branch));