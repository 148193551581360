import React,{Component} from "react";
import {_postCareerData} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import Modal from 'react-bootstrap-modal';

class ApplyPosition extends Component {
  constructor(props) {
    super(props);  
    this.state = {
        careerId:'',
        position:'',

        name:'',
        email:'',
        mobile:'',
        resume_file:'',
        message:'',

        nameError:null,
        emailError:null,
        mobileError:null,
        resume_fileError:null,

        errorMsg:null,
        errorClass:''


    }
  }

  _onlyNumber = (e) =>{
    const re = /[0-9]+/g;
        if (!re.test(e.key)) {
        e.preventDefault();
      }
  }


componentWillReceiveProps(nextProps) {
    let careerInfo = nextProps.careerInfo;
    if(careerInfo){
        this.setState({careerId:careerInfo.career_id,position:careerInfo.position});
    }
 }

    addFile(evt){
        if(evt.target.files[0] !== undefined){    
        if (evt.target.files[0].type !== ''  && (!evt.target.files[0].type.includes('image/')) ) {
                this.setState({resume_fileError:null,resume_file: evt.target.files[0] });
            }else{
                this.setState({resume_fileError:'Select valid file format',resume_file:'' });
            }
        }
    }

    _closeModal = () =>{
        this.setState({
            name:'',email:'',mobile:'',resume_file:'',message:'',nameError:null,
            emailError:null,mobileError:null,resume_fileError:null,errorMsg:'',errorClass:''});
    }

 _handleValidation(evt){
        var nameError = '';
        var emailError = '';
        var mobileError = '';
        var resume_fileError = '';
        var reWhiteSpace = new RegExp(/^\s+$/);
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if (this.state.name == '' || this.state.name == null) {
          nameError = 'Enter your name';
        }
        if (this.state.email === '' || re.test(this.state.email) === false) {
            emailError = 'Enter valid email';
        }
        if (this.state.mobile === '' || reWhiteSpace.test(this.state.mobile) === true) {
            mobileError = 'Enter 10 digit mobile number';
        }

        if (this.state.resume_file === '' || this.state.resume_file == null) {
          resume_fileError = 'Add your resume';
        }
      
        if (nameError || emailError || mobileError || resume_fileError !== '' ){
      
          this.setState({ nameError : nameError,
                          emailError : emailError,
                          mobileError : mobileError,
                          resume_fileError : resume_fileError });
        }else{
          this.setState({ nameError : '',
                          emailError : '',
                          mobileError : '',
                          resume_fileError : '' });
                  this._handleApply(evt);
          }
      }
      _handleChange(evt){
        this.setState({ [evt.target.name]: evt.target.value });
      }

    _handleApply = (evt) =>{
        var that  = this;
        let data = new FormData();
    
        that.setState({loadingFlag:true });
    
        data.append('name', this.state.name);
        data.append('email', this.state.email);
        data.append('mobile', this.state.mobile);
        data.append('resume_file', this.state.resume_file);
        data.append('message', this.state.message);
        data.append('career_id', this.state.careerId);
        data.append('position', this.state.position);
    
        this.props._postCareerData(data).then(response => { 
          if(response.data.success){
        that.setState({loadingFlag:false,errorMsg:'Form submitted successfully',errorClass:'alert alert-success' });
          }else{
        that.setState({loadingFlag:false,errorMsg:'Error in form submission',errorClass:'alert alert-danger' });
          }
        }).catch(function (error) {      
          throw error;
        });
    }

render(){
  
  return (
        <div className="position-modals">
        <div className="modal fade" id="buyer_feedback" role="dialog"
        aria-labelledby="buyer_feedback" aria-hidden="true">
        <div className="modal-dialog modal-lg">
        
        
        <div className="modal-content">
            <h1>Job Application Form</h1>
            
            <div className="modal-body">

            <div className="position-form">
            <form>
            {this.state.errorMsg &&
                    <div class={this.state.errorClass} role="alert">
                        {this.state.errorMsg}
                    </div>}

                <div className="row">

                <div className="col-md-6">
                    <div className="form-group">
                    <label>Enter Name</label>
                    <input type="text" 
                          name="name" 
                          value={this.state.value}
                          className="form-control" 
                          onChange={ (evt) => this._handleChange(evt) }
                          placeholder="Enter Your Full Name" />
                             {this.state.nameError && 
                            <span className="text-danger"> {this.state.nameError} </span>}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                    <label>Enter Email</label>
                    <input type="text" 
                           name="email" 
                           value={this.state.email}
                           className="form-control" 
                           onChange={ (evt) => this._handleChange(evt) }
                           placeholder="Enter Your Email Address" />
                              {this.state.emailError && 
                            <span className="text-danger"> {this.state.emailError} </span>}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                    <label>Position Applied For</label>
                        <input type="text"
                               name="" 
                               className="form-control" 
                               readOnly 
                               value={this.state.position} />
                    </div>
                </div>

                    <div className="col-md-6">
                    <div className="form-group">
                    <label>Enter Mobile Number</label>
                    <input type="text" 
                           name="mobile" 
                           maxLength="10"
                           className="form-control" 
                           value={this.state.mobile}
                           onChange={ (evt) => this._handleChange(evt) }
                           onKeyPress={(evt)=>this._onlyNumber(evt)}
                           placeholder="Enter Your Mobile Number" />
                              {this.state.mobileError && 
                            <span className="text-danger"> {this.state.mobileError} </span>}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                    
                        <label>Upload Your Resume</label>
                        <input type="file" 
                               placeholder="Upload your resume" 
                               name="resume_file" 
                               onChange={(evt)=>this.addFile(evt)}
                               accept="application/pdf,application/msword,.docx"
                               className="form-control upload-file" />
                                  {this.state.resume_fileError && 
                            <span className="text-danger"> {this.state.resume_fileError} </span>}
                    
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                    <label>Message(optional)</label>
                        <textarea rows="3"
                                  className="form-control"
                                  name="message"
                                  onChange={(evt)=>this._handleChange(evt)}
                                  value={this.state.message}
                                   placeholder="Message"></textarea>
                    
                    </div>
                </div>

                </div>
            </form>
            </div>
            <div className="modal-footer">
            <div className="form-btn">

        <button class="btnApply" disabled={this.state.loadingFlag} onClick={(evt)=>this._handleValidation(evt)}>
            {this.state.loadingFlag && <i class="fa fa-refresh fa-spin"></i>}Register
        </button>

                {/* <a href="#" className="btnRegister">Register</a> */}
            <button type="button" className="btnClose" data-dismiss="modal" onClick={this._closeModal}>Close</button>
            </div>
            </div>
        </div>
        
        </div>
    </div>
    </div>
    </div>

    );  
  }
}
  
ApplyPosition.propTypes = {
  _postCareerData: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_postCareerData})(ApplyPosition));


