import React,{Component} from "react";
import logo from '../../assets/images/logo.png';
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

render(){
  return (
    <React.Fragment>
    <div className="footer-section">
     <div className="container-fluid">
      <div className="footer-content">
        <div className="row">

          <div className="col-md-3">
            <div className="footer-block wow fadeInLeft" data-wow-delay="0.5s" 
                style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
              <img src={logo} className="img-fluid d-block" />
            </div>
          </div>

          <div className="col-md-5">
            <div className="footer-block">
              <h4>महत्वाच्या लिंक्स</h4>
                <div className="row">
                <div className="col-md-4">
                  <div className="footer-link">
                    <Link to={'./'}>मुख्य पान</Link>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./deposit-scheme'}>ठेव योजना</Link>
                </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./loan-scheme'}>कर्ज योजना</Link>
                </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./founder-director'}>संघटना</Link>
                </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./about-us'}>संस्था परिचय</Link>
                </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./gallery'}>गॅलरी</Link>
                </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./download'}>डाउनलोड</Link>
                </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./annual-report'}>वार्षिक अहवाल</Link>
                </div>
                </div>  

                <div className="col-md-4">
                  <div className="footer-link">
                  <Link to={'./contact'}>संपर्क</Link>
                </div>
                </div>

              </div>

              

            </div>
          </div>

          <div className="col-md-4">
            <div className="footer-block-btn wow fadeInRight" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInRight'}}>

              <a href="#" className="downloadForm-btn"><i className="fa fa-download" aria-hidden="true"></i>&nbsp;&nbsp;डाउनलोड फॉर्म</a>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="footer-para">
  <p>कॉपी राइट &copy; सर्व हक्क राखीव.</p>
  </div>
</React.Fragment>
    );  
  }
}

export default Footer;