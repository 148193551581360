import { SAVE_PROFILE_PIC,GET_TOTAL_SUM } from '../actions/types';

const initialStateDetails = {
    saveProPic:'',
    sumTotal:[],
};

export default (state = initialStateDetails, action = {}) => {
  switch(action.type) {
        case SAVE_PROFILE_PIC:
         return{
           saveProPic: action.saveProPic
        }; 
        case GET_TOTAL_SUM:
          return{
            sumTotal: action.sumTotal,
         }; 

       default: return state;
  }
}