var moment = require('moment');

function _formatDate(date) {
    if(moment(date).isValid()){
     return moment(date).format('MMM, DD YYYY'); 
    }else{
      return '-'; 
    }
  }

  function _downloadFile(file_path) {
    console.log(file_path)
    var a = document.createElement('A');
    a.href = file_path;
    a.target="blank";
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

module.exports = {_formatDate, _downloadFile };