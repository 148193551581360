import React,{Component} from "react";
import {_downloadList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import {_downloadFile} from '../sub_parts/helper';

var configData = require('../../../config.js');
var downloadFilePath = configData.downloadFilePath;


class Download extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      downloadData:[],
      loadingFlag:false,
      loadingData:"loading data..."

    }
  }

  _downloadList = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._downloadList().then(response => { 
        if(response.data.success){
          that.setState({loadingFlag:false,downloadData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,downloadData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',downloadData:[]});
     });
  }

  componentDidMount(){
    this._downloadList();
  }

  _download = (evt,file) =>{
    if(file){
    var file_path = downloadFilePath+file;
     _downloadFile(file_path);
    }
  }


render(){
  const { downloadData } = this.state;
  
  return (
    <div className="download-section">
    <div className="title-block">
   <h1>अर्ज व माहिती</h1>
   </div>
   <div className="container">
     <div className="download-block">
     
     {downloadData && downloadData.length > 0 && downloadData.map((data,index)=>(
         <div className="download-box"  key={index}>
            <div className="row">
           <div className="col-md-9">
           <div className="download-content">
             <p>{data.download_title ? data.download_title : ''}</p>
           </div>
         </div>
              <div className="col-md-3">
                <div className="download-btn">
                  <a href="jaavascript:void(0);" className="pdf-btn" onClick={(evt)=>this._download(evt,data.download_file)}>
                    <i className="fa fa-download" aria-hidden="true"></i>
                    &nbsp;&nbsp; डाउनलोड अर्ज
                  </a>
                </div>
              </div>
            </div>
         </div>))}

         {this.state.loadingFlag &&
        <div className="row loadingData">
        <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
        <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
        </div>}

     </div>
   </div>
 </div>
    );  
  }
}
  
Download.propTypes = {
  _downloadList: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_downloadList})(Download));