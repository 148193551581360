import React,{Component} from "react";
import {_postCareerData} from '../../../actions/simpleActions';

var configData = require('../../../config.js');
var schemeImage = configData.schemeImage;
var defaultImg = configData.defaultImg;

class ViewDetails extends Component {
  constructor(props) {
    super(props);  
    this.state = {
    }
  }

render(){
    const {depositData} = this.props;

    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXX');
    console.log(this.props.depositData)
  
  return (
        <div className="position-modals">
        <div className="modal fade" id="scheme_modal" role="dialog"
        aria-labelledby="scheme_modal" aria-hidden="true">
        <div className="modal-dialog modal-xl">
        <div className="modal-content">
       
        <div className="modal-header">
          <h4 className="modal-title">{depositData.scheme_name ? depositData.scheme_name : ''}</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
        <div className="modal-body">
          <div className="row">
    
        <div class="col-md-12 about_content">
            <img src={schemeImage+depositData.scheme_image} class="img-fluid d-block about_image" />
        
        <p className="about_desc" dangerouslySetInnerHTML={{__html: depositData.description}} />
        </div>
        </div>
       </div>
        
       
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    
    </div>
    </div>
    </div>

    );  
  }
}
  

export default ViewDetails;


