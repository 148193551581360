import React,{Component} from "react";
import {_branchMemberList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

var configData = require('../../../config.js');
var memberImgPath = configData.memberImgPath;
var defaultImg = configData.defaultImg;

class BranchConsult extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      branchMemList:[],
      loadingFlag:false,
      loadingData:"loading data..."

    }
  }


  _branchMemberList = () => {
    let that= this;
    let data = new FormData();
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._branchMemberList().then(response => { 
        if(response.data.data.length  > 0){
          that.setState({loadingFlag:false,branchMemList:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,branchMemList:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',branchMemList:[]});
     });
  }

  componentDidMount(){
    this._branchMemberList();
  }

render(){
  const {branchMemList} = this.state;

  return (
   
    <div class="branchVendor">
    <div class="title-block">
    <h1>शाखा सल्लागार समिती</h1>
    </div>
    <div class="container">
      <div class="branchVendor-data">
        
        <div class="row">
        {branchMemList && branchMemList.length > 0 && branchMemList.map((branch,index)=>(
          <div class="col-md-6" key={index}>
            <div class="vendor-block">
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-heading">
                   {branch.branch_name ? branch.branch_name : ''}
                  </div>
                </div>
                {branch.branchMemberDetails && branch.branchMemberDetails.length > 0 && branch.branchMemberDetails.map((mem,index1)=>(
                    <div class="col-md-4" key={index1}>
                    <img src={mem.profile_pic ? memberImgPath+mem.profile_pic : defaultImg} class="img-fluid d-block" />
                     <p>{mem.salutation ? mem.salutation : ''}{" "}{mem.full_name ? mem.full_name : ''}</p>
                    </div>))}
                    </div>
                    </div>
                </div>
                ))}
              </div>
              {this.state.loadingFlag &&
                    <div className="row loadingData">
                    <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
                    <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
                    </div>}
            </div>
          </div>
        </div>
    );  
  }
}
  
BranchConsult.propTypes = {
    _branchMemberList: PropTypes.func.isRequired
  }
  
  export default withRouter(connect(null, {_branchMemberList})(BranchConsult));