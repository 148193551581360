import React,{Component} from "react";
import {_aboutUsList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

var configData = require('../../../config.js');
var aboutImgPath = configData.aboutImgPath;

class AboutUs extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      aboutData:[],
      loadingFlag:false,
      loadingData:"loading data...",
      image:'',

    }
  }

  _aboutUsList = () => {
    let that= this;
    let data = new FormData();
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});
    data.append('specification', 'chairman_msg');

    this.props._aboutUsList(data).then(response => { 
      if(response.data.success){
          console.log(response.data.data[0].description)
          that.setState({loadingFlag:false,aboutData:response.data.data[0].description,
            image:response.data.data[0].about_image, loadingData:''});
        }else{
           that.setState({loadingFlag:false,aboutData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',aboutData:[]});
     });
  }

  componentDidMount(){
    this._aboutUsList();
  }

render(){
  const {aboutData} = this.state;

  return (
   
        <div className="abt-section">
            <div className="title-block">
                <h1>अध्यक्षीय मनोगत</h1>
            </div>
           
            <div className="container">
      <div className="abt-data">
        <div className="row">
          <div className="col-md-3">
            <img src={aboutImgPath+this.state.image} className="img-fluid d-block" />
          </div>

          <div className="col-md-9">
            <div className="abt-content">
              <div dangerouslySetInnerHTML={{__html:aboutData}} />
          </div>
          </div>
        </div>
      </div>

      {this.state.loadingFlag &&
                <div className="row loadingData">
                <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
                <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
                </div>}

          </div>
        </div>
    );  
  }
}
  
AboutUs.propTypes = {
    _aboutUsList: PropTypes.func.isRequired
  }
  
  export default withRouter(connect(null, {_aboutUsList})(AboutUs));