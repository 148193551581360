import React,{Component} from "react";

import {_contactData} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import InformationForm from './InformationForm';
import FeedbackForm from './FeedbackForm';

var configData = require('../../../config.js');
var downloadFilePath = configData.downloadFilePath;

class Contact extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      contactData:[],
      loadingFlag:false,
      loadingData:"loading data..."
    }
  }

  _contactData = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._contactData().then(response => { 
        if(response.data.success){
          that.setState({loadingFlag:false,contactData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,contactData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',contactData:[]});
     });
  }

  componentDidMount(){
    this._contactData();
  }

  _renderMap(lat,lang){

    return(
      <iframe src={'https://maps.google.com/maps?q='+lat+', '+lang+'&z=15&output=embed'} width="100%" height="200" frameborder="0" style={{border:'0'}}></iframe>

    )
  }

render(){
  const {contactData} = this.state;
  
  return (
    <div className="contact-section">
    <div className="title-block">
      <h1>संपर्क</h1>
    </div>
    <div className="container">
      <div className="contact-content">
       
      {this.state.loadingFlag &&
        <div className="row loadingData">
        <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
        <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
        </div>}

        <div className="row">
  
  {contactData && contactData.length > 0 && contactData.map((contact,index)=>(
        <React.Fragment key={index}>
          
          <div className="col-md-6">
            <div className="contact-block">
              <h4>{contact.office_name ? contact.office_name : ''}</h4>
              <p>{contact.address ? contact.address : ''},
                 {contact.city ? contact.city : ''}{","}{contact.state ? contact.state : ''} - {contact.pin ? contact.pin : ''}. <br />
                  Telephone no. : {contact.telephone ? contact.telephone : ''}<br />
                  Email : {contact.email ? contact.email : ''}

                </p>
            </div>
          </div>
  
          <div className="col-md-6">
             <div className="contact-block">
             {/* <iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=40.7127837,-74.0059413&amp;key=YOUR_API_KEY"></iframe> */}

{this._renderMap(contact.latitude,contact.longitude)}





               {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="200" frameborder="0" style={{border:'0'}}></iframe> */}
             </div>
          </div>
          </React.Fragment>))}
      <InformationForm />
  
    <FeedbackForm />
  
        </div>
      </div>
    </div>
  </div>
    );  
  }
}
  
Contact.propTypes = {
  _contactData: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_contactData})(Contact));