import React,{Component} from "react";
class LonScheme extends Component {
  constructor(props) {
    super(props);  
    
  }

render(){
  return (
	  <React.Fragment>
	<div className="yojna-section">
	<div className="title-block">
  <h1>VARIOUS LOAN SCHEMES</h1>
  </div>
	  <div className="container">
		  <div className="yojna-block">
			  <div className="row">

				  <div className="col-md-4">
					  <div className="yojna-img">
						  <a data-toggle="modal" data-target="#generalDebt"><img src="../../assets/images/samanyaKarja.jpg" className="img-fluid mx-auto d-block" /></a>

						  <div className="text wow fadeInLeft" data-wow-delay="0.5s">

							  <a data-toggle="modal" data-target="#generalDebt"><h2>General Debt</h2></a>
						  </div>
					  </div>

				  </div>

				  <div className="col-md-4">
					  <div className="yojna-img">
						  <a data-toggle="modal" data-target="#autoLoan"><img src="../../assets/images/vahanKarja.jpg" className="img-fluid mx-auto d-block" /></a>

						  <div className="text wow fadeInLeft" data-wow-delay="0.5s">
							  <a data-toggle="modal" data-target="#autoLoan"><h2>Auto Loan</h2></a>
						  </div>
					  </div>

				  </div>

				  <div className="col-md-4">
					  <div className="yojna-img">
						  <a data-toggle="modal" data-target="#other"><img src="../../assets/images/gruhKarja.jpg" className="img-fluid mx-auto d-block" /></a>

						  <div className="text wow fadeInLeft" data-wow-delay="0.5s">
							  <a data-toggle="modal" data-target="#other"><h2>Home-Shop-Office-Purchase-Loan</h2></a>
						  </div>
					  </div>

				  </div>

				  <div className="col-md-4">
					  <div className="yojna-img">
						  <a data-toggle="modal" data-target="#businessLoan"><img src="../../assets/images/vyavsayKarja.png" className="img-fluid mx-auto d-block" /></a>

						  <div className="text wow fadeInLeft" data-wow-delay="0.5s">
							  <a data-toggle="modal" data-target="#businessLoan"><h2>Business Loan</h2></a>
						  </div>
					  </div>

				  </div>
				  

				  <div className="col-md-4">
					  <div className="yojna-img">
						  <a data-toggle="modal" data-target="#education"><img src="../../assets/images/edu1.jpg" className="img-fluid mx-auto d-block" /></a>

						  <div className="text wow fadeInLeft" data-wow-delay="0.5s">
							  <a data-toggle="modal" data-target="#education"><h2>Educational Loan</h2></a>
						  </div>
					  </div>

				  </div>

				  <div className="col-md-4">
					  <div className="yojna-img">
						  <a data-toggle="modal" data-target="#credit"><img src="../../assets/images/credit.png" className="img-fluid mx-auto d-block" /></a>

						  <div className="text wow fadeInLeft" data-wow-delay="0.5s">
							  <a data-toggle="modal" data-target="#credit"><h2>Cash Credit Loan</h2></a>
						  </div>
					  </div>

				  </div>

		<div className="col-md-4">
		  <div className="yojna-img">
			<a data-toggle="modal" data-target="#suvarnTaran"><img src="../../assets/images/gold.png" className="img-fluid mx-auto d-block" /></a>

			<div className="text wow fadeInLeft" data-wow-delay="0.5s">
			  <a data-toggle="modal" data-target="#suvarnTaran"><h2>Suvarntaran Loan</h2></a>
			</div>
		  </div>

		</div>

			  

			  </div>
		  </div>
	  </div>
  </div>


  <div className="modat-content">

<div className="modal fade" id="generalDebt">
  <div className="modal-dialog modal-xl">
	<div className="modal-content">
	
	 
	  <div className="modal-header">
		<h4 className="modal-title">General Debt</h4>
		<button type="button" className="close" data-dismiss="modal">&times;</button>
	  </div>
	  
	
	  <div className="modal-body">
		<div className="row">
			<div className="col-md-6">
				<div className="yojna-img">
					<img src="../../assets/images/samanyaKarja.jpg" className="img-fluid d-block" />
				</div>
			</div>

			<div className="col-md-6">
				<div className="yojna-text">
					<ul>

						<li className="even">
							<div className="row">
								<div className="col-md-12 thev-info">
									<p>Rs. 5,000 Te Rs. 1,00,000</p>
								</div>
								
							</div>
						</li>

						<li className="odd">
							<div className="row">
					<div className="col-md-12 thev-info">
					  <p>Bail - Emergency Loans</p>
					</div>
					
				  </div>
						</li>

						<li className="even">
							<div className="row">
					<div className="col-md-12 thev-info">
					  <p>Weeks 40 Months</p>
					</div>
					
				  </div>
						</li>

						<li className="odd">
						
								<div className="row">
					<div className="col-md-12 thev-info">
					  <p>The interest rate d. Sa. The. Sh. 17%</p>
					</div>
					
				  </div>
							
						</li>

						
					</ul>
				</div>
			</div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<div className="karja-tc">
			  <h5>Necessary documents required for the loan</h5>
			  <h6>Following documents along with the borrower and guarantor should be submitted in certified copy.</h6>

			  <p>1)&nbsp;&nbsp;Borrower + guarantor (2): - Photo one each</p>

			  <p>2)&nbsp;&nbsp;Borrower + Guarantor (2): - Ration Card - Proof of Residence (Certificate)</p>

			  <p>3)&nbsp;&nbsp;Borrower + Guarantor (2): - Current Month Light Bill / Rent Receipt / Telephone Bill / Rent Agreement (Certificate)</p>

			  <p>4)&nbsp;&nbsp;Borrower + Guarantor (2): - PAN Card Xerox / Driving License (Certificate)</p>

			  <p>5)&nbsp;&nbsp;Borrower  : Bank Statement, 5 Cheques</p>

			  <p>6)&nbsp;&nbsp;Borrower + Guarantor (2): - Income tax paper for current year and last two years</p>

			  <p>7)&nbsp;&nbsp;Borrower + Guarantor (2): - Form No. 16A, iCard Xerox, Salary Slip</p>

			  <p>8)&nbsp;&nbsp;Borrower + Guarantor (2): - APMC / Gumasta License / Business License</p>


			</div>
		  </div>
		</div>

		<div className="sanstha-info">
		  <h6>Spectacular financial position of the organization at the end of March 2019</h6>
		<ul>

		  <li>
			<div className="sanstha-count">
			  <p>Number of members</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>94075</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Member deposits</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Rs. 610.87 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Swanidhi</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 66.19 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Playing capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 787.44 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Recovered share capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 35.97 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Member Loans / Mumbai Bank</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 598.21 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>N P A</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>3.49%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>C D Ratio</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>76.13%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Investment</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 151.67 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Audit class continuous</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>A</p>
			</div>
		  </li>

		</ul>
	  </div>


	  </div>
	  
	 
	  <div className="modal-footer">
		<button type="button" className="modal-btn" data-dismiss="modal">Close</button>
	  </div>
	  
	</div>
  </div>
</div>




<div className="modal fade" id="autoLoan">
  <div className="modal-dialog modal-xl">
	<div className="modal-content">
	
	 
	  <div className="modal-header">
		<h4 className="modal-title">Auto Loan</h4>
		<button type="button" className="close" data-dismiss="modal">&times;</button>
	  </div>
	  
	
	  <div className="modal-body">
		<div className="row">
		  <div className="col-md-6">
			<div className="yojna-img">
			  <img src="../../assets/images/vahanKarja.jpg" className="img-fluid d-block" />
			</div>
		  </div>

		  <div className="col-md-6">
			<div className="yojna-text">
			  <ul>

				<li className="even">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Debt Limit New Vehicle: - 60,000,000 / -</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Individual - 60 months - 14%</p>
					</div>
					
				  </div>
				</li>

				<li className="even">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Occupational - 48 months - 15%</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				
					<div className="row">
					<div className="col-md-12 thev-info">
					  <p>Older vehicles (5 years old): - 500,000 / - 36 months - 16% - (50% of valuation)</p>
					</div>
					
				  </div>
				  
				</li>

				
			  </ul>
			</div>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<div className="karja-tc">
			 <h5>Necessary documents required for the loan</h5>
			  <h6>Following documents along with the borrower and guarantor should be submitted in certified copy.</h6>

			  <p>1)&nbsp;&nbsp;Borrower + guarantor (2): - Photo one each</p>

			  <p>2)&nbsp;&nbsp;Borrower + Guarantor (2): - Ration Card - Proof of Residence (Certificate)</p>

			  <p>3)&nbsp;&nbsp;Borrower + Guarantor (2): - Current Month Light Bill / Rent Receipt / Telephone Bill / Rent Agreement (Certificate)</p>

			  <p>4)&nbsp;&nbsp;Borrower + Guarantor (2): - PAN Card Xerox / Driving License (Certificate)</p>

			  <p>5)&nbsp;&nbsp;Borrower  : Bank Statement, 5 Cheques</p>

			  <p>6)&nbsp;&nbsp;Borrower + Guarantor (2): - Income tax paper for current year and last two years</p>

			  <p>7)&nbsp;&nbsp;Borrower + Guarantor (2): - Form No. 16A, iCard Xerox, Salary Slip</p>

			  <p>8)&nbsp;&nbsp;Borrower + Guarantor (2): - APMC / Gumasta License / Business License</p>

			  <p>9)&nbsp;&nbsp;Borrower: - Vehicle Purchase Quotation, Booking Receipt, RC Book & Insurance Document, Debt Burden Record of Institution, Invoice Bill, Tax Receipt.</p>


			</div>
		  </div>
		</div>

		<div className="sanstha-info">
		  <h6>Spectacular financial position of the organization at the end of March 2019</h6>
		<ul>

		  <li>
			<div className="sanstha-count">
			  <p>Number of members</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>94075</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Member deposits</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Rs. 610.87 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Swanidhi</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 66.19 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Playing capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 787.44 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Recovered share capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 35.97 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Member Loans / Mumbai Bank</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 598.21 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>N P A</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>3.49%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>C D Ratio</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>76.13%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Investment</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 151.67 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Audit class continuous</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>A</p>
			</div>
		  </li>

		</ul>
	  </div>


	  </div>
	  
	 
	  <div className="modal-footer">
		<button type="button" className="modal-btn" data-dismiss="modal">Close</button>
	  </div>
	  
	</div>
  </div>
</div>



<div className="modal fade" id="businessLoan">
  <div className="modal-dialog modal-xl">
	<div className="modal-content">
	
	 
	  <div className="modal-header">
		<h4 className="modal-title">Business Loan</h4>
		<button type="button" className="close" data-dismiss="modal">&times;</button>
	  </div>
	  
	
	  <div className="modal-body">
		<div className="row">
		  <div className="col-md-6">
			<div className="yojna-img">
			  <img src="../../assets/images/vyavsayKarja.png" className="img-fluid d-block" />
			</div>
		  </div>

		  <div className="col-md-6">
			<div className="yojna-text">
			  <ul>

				<li className="even">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Commercial (Land) Loan: - Loan up to Rs. 1,50,000,000 84 to 180 months: - 16%</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>(50% of land mortgage valuation)</p>
					</div>
					
				  </div>
				</li>

			  </ul>
			</div>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<div className="karja-tc">
			 <h5>Necessary documents required for the loan</h5>
			  <h6>Following documents along with the borrower and guarantor should be submitted in certified copy.</h6>

			  <p>1)&nbsp;&nbsp;Borrower + guarantor (2): - Photo one each</p>

			  <p>2)&nbsp;&nbsp;Borrower + Guarantor (2): - Ration Card - Proof of Residence (Certificate)</p>

			  <p>3)&nbsp;&nbsp;Borrower + Guarantor (2): - Current Month Light Bill / Rent Receipt / Telephone Bill / Rent Agreement (Certificate)</p>

			  <p>4)&nbsp;&nbsp;Borrower + Guarantor (2): - PAN Card Xerox / Driving License (Certificate)</p>

			  <p>5)&nbsp;&nbsp;Borrower  : Bank Statement, 5 Cheques</p>

			  <p>6)&nbsp;&nbsp;Borrower + Guarantor (2): - Income tax paper for current year and last two years</p>

			  <p>7)&nbsp;&nbsp;Borrower + Guarantor (2): - Form No. 16A, iCard Xerox, Salary Slip</p>

			  <p>8)&nbsp;&nbsp;Borrower + Guarantor (2): - APMC / Gumasta License / Business License</p>

			  <p>9)&nbsp;&nbsp;Borrower: - Original File, Chain Agreement, Builder NOC, APMCNOC, Loan Approval up to 70% of Property Appraisal</p>


			</div>
		  </div>
		</div>

		<div className="sanstha-info">
		  <h6>Spectacular financial position of the organization at the end of March 2019</h6>
		<ul>

		  <li>
			<div className="sanstha-count">
			  <p>Number of members</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>94075</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Member deposits</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Rs. 610.87 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Swanidhi</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 66.19 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Playing capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 787.44 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Recovered share capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 35.97 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Member Loans / Mumbai Bank</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 598.21 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>N P A</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>3.49%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>C D Ratio</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>76.13%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Investment</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 151.67 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Audit class continuous</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>A</p>
			</div>
		  </li>

		</ul>
	  </div>


	  </div>
	  
	 
	  <div className="modal-footer">
		<button type="button" className="modal-btn" data-dismiss="modal">Close</button>
	  </div>
	  
	</div>
  </div>
</div>




<div className="modal fade" id="education">
  <div className="modal-dialog modal-xl">
	<div className="modal-content">
	
	 
	  <div className="modal-header">
		<h4 className="modal-title">Educational Loan</h4>
		<button type="button" className="close" data-dismiss="modal">&times;</button>
	  </div>
	  
	
	  <div className="modal-body">
		<div className="row">
		  <div className="col-md-6">
			<div className="yojna-img">
			  <img src="../../assets/images/edu1.jpg" className="img-fluid d-block" />
			</div>
		  </div>

		  <div className="col-md-6">
			<div className="yojna-text">
			  <ul>

				<li className="even">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Debt limit is Rs. : - Up to Rs.1,00,000 / -</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Duration: - 7 years</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>The interest rate d. Sa. The. Sh. 14%</p>
					</div>
					
				  </div>
				</li>

			  </ul>
			</div>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<div className="karja-tc">
			 <h5>Necessary documents required for the loan</h5>
			  <h6>Following documents along with the borrower and guarantor should be submitted in certified copy.</h6>

			  <p>1)&nbsp;&nbsp;Borrower + guarantor (2): - Photo one each</p>

			  <p>2)&nbsp;&nbsp;Borrower + Guarantor (2): - Ration Card - Proof of Residence (Certificate)</p>

			  <p>3)&nbsp;&nbsp;Borrower + Guarantor (2): - Current Month Light Bill / Rent Receipt / Telephone Bill / Rent Agreement (Certificate)</p>

			  <p>4)&nbsp;&nbsp;Borrower + Guarantor (2): - PAN Card Xerox / Driving License (Certificate)</p>

			  <p>5)&nbsp;&nbsp;Borrower  : Bank Statement, 5 Cheques</p>

			  <p>6)&nbsp;&nbsp;Borrower + Guarantor (2): - Income tax paper for current year and last two years</p>

			  <p>7)&nbsp;&nbsp;Borrower + Guarantor (2): - Form No. 16A, iCard Xerox, Salary Slip</p>

			  <p>8)&nbsp;&nbsp;Borrower + Guarantor (2): - APMC / Gumasta License / Business License</p>

			  <p>9)&nbsp;&nbsp;Borrower: - Mortgage Property Original Documents Admission Receipt.</p>


			</div>
		  </div>
		</div>

		<div className="sanstha-info">
		  <h6>Spectacular financial position of the organization at the end of March 2019</h6>
		<ul>

		  <li>
			<div className="sanstha-count">
			  <p>Number of members</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>94075</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Member deposits</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Rs. 610.87 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Swanidhi</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 66.19 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Playing capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 787.44 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Recovered share capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 35.97 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Member Loans / Mumbai Bank</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 598.21 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>N P A</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>3.49%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>C D Ratio</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>76.13%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Investment</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 151.67 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Audit class continuous</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>A</p>
			</div>
		  </li>

		</ul>
	  </div>


	  </div>
	  
	 
	  <div className="modal-footer">
		<button type="button" className="modal-btn" data-dismiss="modal">Close</button>
	  </div>
	  
	</div>
  </div>
</div>






<div className="modal fade" id="credit">
  <div className="modal-dialog modal-xl">
	<div className="modal-content">
	
	 
	  <div className="modal-header">
		<h4 className="modal-title">Cash Credit Loan</h4>
		<button type="button" className="close" data-dismiss="modal">&times;</button>
	  </div>
	  
	
	  <div className="modal-body">
		<div className="row">
		  <div className="col-md-6">
			<div className="yojna-img">
			  <img src="../../assets/images/credit.png" className="img-fluid d-block" />
			</div>
		  </div>

		  <div className="col-md-6">
			<div className="yojna-text">
			  <ul>

				<li className="even">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Cash Credit Loan: - 50,000,000 / - (50% renewal per annum of land market valuation)</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Loan up to Rs. 50 lakhs - 84 to 120 months - 16%</p>
					</div>
					
				  </div>
				</li>

			   
			  </ul>
			</div>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<div className="karja-tc">
			 <h5>Necessary documents required for the loan</h5>
			  <h6>Following documents along with the borrower and guarantor should be submitted in certified copy.</h6>

			  <p>1)&nbsp;&nbsp;Borrower + guarantor (2): - Photo one each</p>

			  <p>2)&nbsp;&nbsp;Borrower + Guarantor (2): - Ration Card - Proof of Residence (Certificate)</p>

			  <p>3)&nbsp;&nbsp;Borrower + Guarantor (2): - Current Month Light Bill / Rent Receipt / Telephone Bill / Rent Agreement (Certificate)</p>

			  <p>4)&nbsp;&nbsp;Borrower + Guarantor (2): - PAN Card Xerox / Driving License (Certificate)</p>

			  <p>5)&nbsp;&nbsp;Borrower  : Bank Statement, 5 Cheques</p>

			  <p>6)&nbsp;&nbsp;Borrower + Guarantor (2): - Income tax paper for current year and last two years</p>

			  <p>7)&nbsp;&nbsp;Borrower + Guarantor (2): - Form No. 16A, iCard Xerox, Salary Slip</p>

			  <p>8)&nbsp;&nbsp;Borrower + Guarantor (2): - APMC / Gumasta License / Business License</p>

			  <p>9)&nbsp;&nbsp;Borrower: - Original file, APMCNOC, loan sanction up to 70% of asset valuation.</p>


			</div>
		  </div>
		</div>

		<div className="sanstha-info">
		  <h6>Spectacular financial position of the organization at the end of March 2019</h6>
		<ul>

		  <li>
			<div className="sanstha-count">
			  <p>Number of members</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>94075</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Member deposits</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Rs. 610.87 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Swanidhi</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 66.19 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Playing capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 787.44 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Recovered share capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 35.97 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Member Loans / Mumbai Bank</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 598.21 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>N P A</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>3.49%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>C D Ratio</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>76.13%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Investment</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 151.67 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Audit class continuous</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>A</p>
			</div>
		  </li>

		</ul>
	  </div>


	  </div>
	  
	 
	  <div className="modal-footer">
		<button type="button" className="modal-btn" data-dismiss="modal">Close</button>
	  </div>
	  
	</div>
  </div>
</div>




<div className="modal fade" id="other">
  <div className="modal-dialog modal-xl">
	<div className="modal-content">
	
	 
	  <div className="modal-header">
		<h4 className="modal-title">Home-Shop-Office- Purchase Loan</h4>
		<button type="button" className="close" data-dismiss="modal">&times;</button>
	  </div>
	  
	
	  <div className="modal-body">
		<div className="row">
		  <div className="col-md-6">
			<div className="yojna-img">
			  <img src="../../assets/images/gruhKarja.jpg" className="img-fluid d-block" />
			</div>
		  </div>

		  <div className="col-md-6">
			<div className="yojna-text">
			  <ul>

				<li className="even">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Home Buying Loan / Construction & Repair Loan: - 1,50,00,000 / - 84 to 240 months - 13%</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Shop / Office Purchase Loan: - 1,50,00,000 / - 84 to 180 months - 15%</p>
					</div>
					
				  </div>
				</li>

			   
			  </ul>
			</div>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<div className="karja-tc">
			 <h5>Necessary documents required for the loan</h5>
			  <h6>Following documents along with the borrower and guarantor should be submitted in certified copy.</h6>

			  <p>1)&nbsp;&nbsp;Borrower + guarantor (2): - Photo one each</p>

			  <p>2)&nbsp;&nbsp;Borrower + Guarantor (2): - Ration Card - Proof of Residence (Certificate)</p>

			  <p>3)&nbsp;&nbsp;Borrower + Guarantor (2): - Current Month Light Bill / Rent Receipt / Telephone Bill / Rent Agreement (Certificate)</p>

			  <p>4)&nbsp;&nbsp;Borrower + Guarantor (2): - PAN Card Xerox / Driving License (Certificate)</p>

			  <p>5)&nbsp;&nbsp;Borrower  : Bank Statement, 5 Cheques</p>

			  <p>6)&nbsp;&nbsp;Borrower + Guarantor (2): - Income tax paper for current year and last two years</p>

			  <p>7)&nbsp;&nbsp;Borrower + Guarantor (2): - Form No. 16A, iCard Xerox, Salary Slip</p>

			  <p>8)&nbsp;&nbsp;Borrower + Guarantor (2): - APMC / Gumasta License / Business License</p>


			</div>

			<div className="loan-term">
			  <h6>A) Housing Society for the required documents</h6>
			  <p>
				1) Agreements Four cell of the original copy in between the builder / Society and the first buyers also get a registration receipt and Chain Agreements / stammpa duty receipt</p>
				<p>
				2) Agreements Four cell of the original copy with the acquired current vendor and jancyakaduna house, situated o . C. Of Xerox</p>

				<p>
				3) Guarantee for the period of transfer of NOC shares for the sale of flats to the original members of the Housing Society and to make the borrower a member of the Society.</p>

				<p>
				4) In case of purchase of stock for purchase of house / purchase purchase before distribution of loan, original purchase or register purchase deed after full payment</p>

				<p>
				. 5) Housing Society n. O. C.
			  </p>
			</div>


			<div className="loan-term">
			  <h6>B) Paper for house purchase from builder</h6>
			  <p>1) Booking agreement between the borrower and the builder, stock deed, purchase deed / register document</p>
				<p>2) Building construction map, plan approved by the divisional municipality / gram panchayat for construction</p>

				<p>3) Builder n. O. C.</p>

				<p>
				4) Receipt of payment given while booking the building</p>

				<p>5)Period of completion of the building, Certificate of Architect / Letter of Occupancy / Letter for payment as per builder's slab
			  </p>
			</div>

			 <div className="loan-term">
			  <h6>C)Purchase of house from CIDCO / MHADA</h6>
			  <p>1)  Letter of Allotment</p>
				<p>2) NOC CIDCO / MHADA</p>

				<p>3) Original Payment CIDCO / MHADA</p>

				<p> 4)Possession Letter / Sale Agreement</p>

			   
			</div>

			<div className="loan-term">
			  <h6>D) Debt take over, purchase of house</h6>
			  <p>1) List of original documents of the borrower's house, shop from the bank</p>
				<p>2) Debt letter from the borrower bank regarding transfer of loan</p>
			</div>



		  </div>
		</div>

		<div className="sanstha-info">
		  <h6>Spectacular financial position of the organization at the end of March 2019</h6>
		<ul>

		  <li>
			<div className="sanstha-count">
			  <p>Number of members</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>94075</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Member deposits</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Rs. 610.87 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Swanidhi</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 66.19 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Playing capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 787.44 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Recovered share capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 35.97 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Member Loans / Mumbai Bank</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 598.21 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>N P A</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>3.49%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>C D Ratio</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>76.13%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Investment</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 151.67 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Audit class continuous</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>A</p>
			</div>
		  </li>

		</ul>
	  </div>


	  </div>
	  
	 
	  <div className="modal-footer">
		<button type="button" className="modal-btn" data-dismiss="modal">Close</button>
	  </div>
	  
	</div>
  </div>
</div>




<div className="modal fade" id="suvarnTaran">
  <div className="modal-dialog modal-xl">
	<div className="modal-content">
	
	 
	  <div className="modal-header">
		<h4 className="modal-title">Suvarntaran Loan</h4>
		<button type="button" className="close" data-dismiss="modal">&times;</button>
	  </div>
	  
	
	  <div className="modal-body">
		<div className="row">
		  <div className="col-md-6">
			<div className="yojna-img">
			  <img src="../../assets/images/gold.png" className="img-fluid d-block" />
			</div>
		  </div>

		  <div className="col-md-6">
			<div className="yojna-text">
			  <ul>

				<li className="even">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p> 70 to 85% of children</p>
					</div>
					
				  </div>
				</li>

				<li className="odd">
				  <div className="row">
					<div className="col-md-12 thev-info">
					  <p>Limit: - 200,000,000 / - 12 months - 13.50%</p>
					</div>
					
				  </div>
				</li>

			   
			  </ul>
			</div>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<div className="karja-tc">
			  <h5>Necessary documents required for the loan</h5>
			  <h6>Following documents along with the borrower and guarantor should be submitted in certified copy.</h6>

			  <p>1) 85% debt of gold valuation</p>
			  <p>2) Cash Credit Facility</p>
			  <p>3) Letter of consent if woman is wealthy</p>
			 


			</div>
		  </div>
		</div>

		<div className="sanstha-info">
		  <h6>Spectacular financial position of the organization at the end of March 2019</h6>
		<ul>

		  <li>
			<div className="sanstha-count">
			  <p>Number of members</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>94075</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Member deposits</p>
			</div>
		  </li>

		   <li>
			<div className="sanstha-count">
			  <p>Rs. 610.87 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Swanidhi</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 66.19 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Playing capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 787.44 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Recovered share capital</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 35.97 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Member Loans / Mumbai Bank</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 598.21 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>N P A</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>3.49%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>C D Ratio</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>76.13%</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Investment</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Rs. 151.67 Koti</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>Audit class continuous</p>
			</div>
		  </li>

		  <li>
			<div className="sanstha-count">
			  <p>A</p>
			</div>
		  </li>

		</ul>
	  </div>


	  </div>
	  
	 
	  <div className="modal-footer">
		<button type="button" className="modal-btn" data-dismiss="modal">Close</button>
	  </div>
	  
	</div>
  </div>
</div>







</div>
  </React.Fragment>
    );  
  }
}
  
  export default LonScheme;