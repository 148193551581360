import React,{Component} from "react";
import {_committeeList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

var configData = require('../../../config.js');
var memberImgPath = configData.memberImgPath;

class CommitteeList extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      committeeData:[],
      loadingFlag:false,
      loadingData:"loading data..."

    }
  }

  _committeeList = () => {
    let that= this;
    let data = new FormData();
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._committeeList().then(response => { 
      if(response.data.success){
          that.setState({loadingFlag:false,committeeData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,committeeData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',committeeData:[]});
     });
  }

  componentDidMount(){
    this._committeeList();
  }

render(){
  const {committeeData} = this.state;

  return (
   
    <div class="management">
    <div class="title-block">
  	<h1>समिती</h1>
    </div>
  	<div class="container">
          {committeeData && committeeData.length > 0 && committeeData.map((comm,index)=>(
  		<div class="committee-text" key={index}>
                <ul>

                  <li class="list-heading">
                    <h6>
                      {comm.committee_name ? comm.committee_name : ''}</h6>
                  </li>

                {comm.memberDetails && comm.memberDetails.length > 0 && comm.memberDetails.map((member,index1)=>(
                    <li class={Math.abs(index1 % 2) == 1 ? 'odd' : 'even'} key={index1}>
                        <div class="row">
                            <div class="col-md-6 thev-info">
                                <p>{member.salutation+' '}{member.full_name} </p>
                            </div>
                            <div class="col-md-6">
                                <p>{member.position ? member.position : ''}</p>
                            </div>
                        </div>
                    </li>))}
                </ul>
            </div>))}

            {this.state.loadingFlag &&
            <div className="row loadingData">
            <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
            <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
            </div>}

    	</div>
     </div>


    );  
  }
}
  
CommitteeList.propTypes = {
    _committeeList: PropTypes.func.isRequired
  }
  
  export default withRouter(connect(null, {_committeeList})(CommitteeList));