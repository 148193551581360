import React,{Component} from "react";
import { Link } from "react-router-dom";

class Services extends Component {
  constructor(props) {
    super(props);  

  }

render(){
	const {SavingSchemeData,LoanSchemeData } = this.props;

	let limit = 5;
  return (
    <div className="services">
		<div className="container">
			<div className="services-data">
				<div className="row">

					<div className="col-md-6">
						<div className="services-block">
							<div className="services-img">
							<a href="vividhThevi.html">	<img src="../../assets/images/1.jpg" className="img-fluid d-block" /></a>
							</div>
							<div className="services-text">
								<h4>ठेव योजना</h4>
								<ul>
									{SavingSchemeData && SavingSchemeData.length > 0 && SavingSchemeData && SavingSchemeData.slice(0,limit).map((saving,index1)=>(

									<li index1={index1}>
										<i className="fa fa-square" aria-hidden="true"></i>&nbsp;<span>
										{saving.scheme_name ? saving.scheme_name : ''}</span></li>
									))}

								<li>
									<div className="readMore">
										<Link to={'./deposit-scheme'}>Read More</Link>
									</div>
								</li>
								</ul>
							</div>
						</div>
					</div>

                    <div className="col-md-6">
						<div className="services-block">
							<div className="services-img">
							<a href="vividhThevi.html">	<img src="../../assets/images/1.jpg" className="img-fluid d-block" /></a>
							</div>
							<div className="services-text">
								<h4>कर्ज योजना</h4>
								<ul>
							{LoanSchemeData && LoanSchemeData.length > 0 && LoanSchemeData && LoanSchemeData.slice(0,limit).map((loan,index2)=>(
								<li key={index2}><i className="fa fa-square" aria-hidden="true"></i>&nbsp;<span>{loan.scheme_name ? loan.scheme_name : ''}</span></li>))}
								<li>
									<div className="readMore">
									<Link to={'./loan-scheme'}>Read More</Link>
									</div>
								</li>
								</ul>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
    );  
  }
}
  
  export default Services;