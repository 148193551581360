import React,{Component} from "react";
import {_getOfficerList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

class OfficerList extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      officerData:[],
      loadingFlag:false,
      loadingData:"loading data..."

    }
  }

  _getOfficerList = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._getOfficerList().then(response => { 
        if(response.data.success){
          that.setState({loadingFlag:false,officerData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,officerData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',officerData:[]});
     });
  }

  componentDidMount(){
    this._getOfficerList();
  }


render(){
  const { officerData } = this.state;

  return (
   
    <div className="container">
      <div className="bank-numbers">
                <ul>

                  <li className="list-heading">
                    <h6>अधिकारी वर्ग</h6>
                  </li>

                  <li className="even">
                    <div className="row">
                      <div className="col-md-1 col-1 thev-info">
                        <p style={{fontWeight:'bold'}}>अ. न</p>
                      </div>
                      <div className="col-md-2 col-2 thev-info">
                        <p style={{fontWeight:'bold'}}>नाव</p>
                      </div>
                       <div className="col-md-3 col-3 thev-info">
                        <p style={{fontWeight:'bold'}}>पद / विभाग</p>
                      </div>
                      <div className="col-md-2 col-2 thev-info">
                        <p style={{fontWeight:'bold'}}>संपर्क</p>
                      </div>
                       <div className="col-md-4 col-4 thev-info" style={{border: '0'}}>
                        <p style={{fontWeight:'bold'}}>ई-मेल</p>
                      </div>
                      
                    </div>
                  </li>

          {officerData && officerData.length > 0 && officerData.map((officer,index)=>(
                  <li className="odd" key={index}>
                    <div className="row">
                      <div className="col-md-1 col-1 thev-info">
                    <p>{++index}</p>
                      </div>
                      <div className="col-md-2 col-2 thev-info">
                        <p>{officer.salutation ? officer.salutation : ''}. {officer.full_name ? officer.full_name : ''}.  </p>
                      </div>
                      <div className="col-md-3 col-3 thev-info">
                        <p>{officer.position ? officer.position : ''}  </p>
                      </div>
                      <div className="col-md-2 col-2 thev-info">
                        <p>{officer.mobile_no ? officer.mobile_no : ''}</p>
                      </div>
                      <div className="col-md-4 col-4 thev-info" style={{border: '0'}}>
                        <p>{officer.email ? officer.email : ''}</p>
                      </div>
                    </div>
                  </li>))}
                </ul>
              </div>
           </div>
    );  
  }
}
  
OfficerList.propTypes = {
  _getOfficerList: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_getOfficerList})(OfficerList));