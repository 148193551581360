import React,{Component} from "react";
import {_galleryList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import {_downloadFile} from '../sub_parts/helper';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

var configData = require('../../../config.js');
var galleryPath = configData.galleryPath;

class Gallery extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      imageData:[],
      videoData:[],
      loadingFlag:false,
      loadingData:"loading data...",

      isOpen:false,
      dataImg:null,

    }
    this.zoomImage  =   this.zoomImage.bind(this);

  }

  zoomImage(evt,img){
    this.setState({isOpen:true,dataImg:img})
  }

  _galleryList = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._galleryList().then(response => { 
        if(response.data.success){
          let result = response.data.data;

          const imageData = result.filter(x=>{
            return x.file_type == 1;
          });

          const videoData = result.filter(x=>{
            return x.file_type == 2;
          });

          that.setState({loadingFlag:false,imageData:imageData,videoData:videoData,loadingData:''});
        }else{
           that.setState({loadingFlag:false,imageData:[],videoData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',imageData:[]});
     });
  }

  componentDidMount(){
    this._galleryList();
  }

render(){
  const { imageData,videoData } = this.state;

  return (
    <div className="gallery-section">

    <div className="title-block">
     <h1>गॅलरी</h1>
   </div>

 <div className="container">
 {this.state.loadingFlag &&
        <div className="row loadingData">
        <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
        <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
        </div>}

   <div className="row">
  {imageData && imageData.length > 0 && imageData.map((gallery,index)=>(
     <div className="col-md-3" key={index}>
     <div className="gallery-data wow zoomIn" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'zoomIn'}}>
        <img className="img-fluid d-block mx-auto" src={gallery.media_file ? galleryPath+gallery.media_file : null} onClick={(evt)=>this.zoomImage(evt,gallery.media_file ? galleryPath+gallery.media_file : null)} />
     </div>
   </div>))}
   </div>
 </div>

 {this.state.isOpen && 
      <Lightbox
          mainSrc={this.state.dataImg}
          onCloseRequest={() => this.setState({ isOpen: false,dataImg:null })} />}

<div className="title-block">
     <h1>Videos</h1>
   </div>

   <div className="container">
   <div className="row">
  {videoData && videoData.length > 0 && videoData.map((gallery,index)=>(
     <div className="col-md-3" key={index}>
     <div className="gallery-data wow zoomIn" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'zoomIn'}}>
     <video className="img-fluid d-block mx-auto" controls="controls" preload="metadata">
              <source src={gallery.media_file ? galleryPath+gallery.media_file : null} type="video/mp4" />
            </video>
     </div>
   </div>))}

   
   </div>
 </div>

</div>
    );  
  }
}
  
Gallery.propTypes = {
  _galleryList: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_galleryList})(Gallery));