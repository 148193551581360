import React,{Component} from "react";
import {_memberList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

var configData = require('../../../config.js');
var memberImgPath = configData.memberImgPath;

class FounderDirector extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      memberData:[],
      loadingFlag:false,
      loadingData:"loading data..."

    }
  }

  _memberList = () => {
    let that= this;
    let data = new FormData();
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});
    data.append('specification', 'founder_director');

    this.props._memberList(data).then(response => { 
      if(response.data.success){
          that.setState({loadingFlag:false,memberData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,memberData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',memberData:[]});
     });
  }

  componentDidMount(){
    this._memberList();
  }

render(){
  const {memberData} = this.state;

  return (
   
    <div class="management">
    <div class="title-block">
       <h1>संस्थापक</h1>
    </div>
       <div class="container">
             <div class="management-data">
                 <div class="row">
                {memberData && memberData.length > 0 && memberData.map((member,index)=>(
                 <div class="col-md-3" key={member}>
                     <div class="team-img wow fadeInDown" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInDown'}}>
                         <div class="img-block">
                             <img src={member.profile_pic ? memberImgPath+member.profile_pic : ''} class="img-fluid d-block" />
                         </div>
                         <div class="img-text">
                             <h3>{member.salutation ? member.salutation : ''}&nbsp;{member.full_name ? member.full_name : ''}</h3>
                             <h6>{member.position ? member.position : ''}</h6>
                         </div>
                     </div>
                 </div>
                ))}

         
        
           </div>
           {this.state.loadingFlag &&
            <div className="row loadingData">
            <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
            <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
            </div>}
       </div>
     </div>
  </div>


    );  
  }
}
  
FounderDirector.propTypes = {
    _memberList: PropTypes.func.isRequired
  }
  
  export default withRouter(connect(null, {_memberList})(FounderDirector));