import React,{Component} from "react";
import {_schemeDetails} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';

import ViewDetails from './ViewDetails';

var configData = require('../../../config.js');
var schemeImage = configData.schemeImage;
var defaultImg = configData.defaultImg;

class TmpDepositScheme extends Component {
  constructor(props) {
    super(props);  
    this.state = {
        schemeData:[],
        loadingFlag:false,
        loadingData:'',
        depositData:[],
      }
    this.openAapplyModalFunc    = this.openAapplyModalFunc.bind(this);

    
  }

  _schemeDetails = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});
    let data = new FormData();
    data.append('specification', 'deposit_scheme');
    this.props._schemeDetails(data).then(response => { 
      if(response.data.success){
          that.setState({loadingFlag:false,schemeData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,schemeData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',schemeData:[]});
     });
  }

  componentDidMount(){
    this._schemeDetails();
  }

  openAapplyModalFunc(evt,data){
    evt.preventDefault();
    this.setState({depositData:data});
    document.getElementById("show_scheme_modal").click(); 

}


render(){
  const {schemeData} = this.state;
  return (
	  <React.Fragment>
    <div className="yojna-section">
		 <div className="title-block">
  <h1>विविध ठेव योजना व आकर्षक व्याजदर</h1>
    </div>
		<div className="container">
			<div className="yojna-block">
				<div className="row">

                {schemeData && schemeData.length > 0 && schemeData.map((scheme,index)=>(
					<div className="col-md-4" key={index}>
						<div className="yojna-img">
							<a data-toggle="modal" onClick={(evt)=>this.openAapplyModalFunc(evt,scheme)}>
                                <img src={scheme.scheme_image ? schemeImage+scheme.scheme_image : defaultImg} className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>

								<a data-toggle="modal" onClick={(evt)=>this.openAapplyModalFunc(evt,scheme)}><h2>{scheme.scheme_name ? scheme.scheme_name : ''}</h2></a>
							</div>
						</div>
					</div>))}

                    

				</div>
                {this.state.loadingFlag &&
                        <div className="row loadingData">
                        <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
                        <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
                        </div>}
			</div>
		</div>
	</div>

        <input type="hidden" id="show_scheme_modal" data-controls-modal="scheme_modal" data-toggle="modal" data-target="#scheme_modal" />

      <ViewDetails depositData={this.state.depositData} />

	</React.Fragment>
	
    );  
  }
}

TmpDepositScheme.propTypes = {
    _schemeDetails: PropTypes.func.isRequired
  }
  
  export default withRouter(connect(null, {_schemeDetails})(TmpDepositScheme));
  