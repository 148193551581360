import React,{Component} from "react";
class DepositScheme extends Component {
  constructor(props) {
    super(props);  
    
  }

render(){
  return (
	  <React.Fragment>
    <div className="yojna-section">
		 <div className="title-block">
  <h1>VARIOUS DEPOSIT SCHEMES AND ATTRACTIVE INTEREST RATES</h1>
    </div>
		<div className="container">
			<div className="yojna-block">
				<div className="row">

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#alpaMudatModel">
                                <img src="../../assets/images/alpaMudat.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>

								<a data-toggle="modal" data-target="#alpaMudatModel"><h2>Alpa Mudat Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#sankalThev"><img src="../../assets/images/sankalp.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#sankalThev"><h2>Sankalp Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#lokmangalThev"><img src="../../assets/images/lokMangal.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#lokmangalThev"><h2>Lok Mangal Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#lakhlaxmiThev"><img src="../../assets/images/lakhlaxmi.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#lakhlaxmiThev"><h2>Lakhlaxmi Thev Yojna</h2></a>
							</div>
						</div>

					</div>
					

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#kalyankari"><img src="../../assets/images/kalyankari.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#kalyankari"><h2>Kalyankari Thev Yojna</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#masikmudat"><img src="../../assets/images/masik.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#masikmudat"><h2>Masik Mudat Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#vikasthev"><img src="../../assets/images/vikas.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#vikasthev"><h2>Vikas Thev Yojna</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#retirement"><img src="../../assets/images/retirement.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#retirement"><h2>Retirement Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#ucchashikshan"><img src="../../assets/images/education.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#ucchashikshan"><h2>Ucchashikshan Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#subhamangal"><img src="../../assets/images/marriage.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#subhamangal"><h2>Subhamangal Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#aavartThev"><img src="../../assets/images/aavart.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#aavartThev"><h2>Aavart Thev</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#lakhlaxmiThevMasik"><img src="../../assets/images/lakhlaxmiMasik.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#lakhlaxmiThevMasik"><h2>Lakhlaxmi Thev Yojna(Masik)</h2></a>
							</div>
						</div>

					</div>

					<div className="col-md-4">
						<div className="yojna-img">
							<a data-toggle="modal" data-target="#balvikasThev"><img src="../../assets/images/balvikas.jpg" className="img-fluid mx-auto d-block" /></a>

							<div className="text wow fadeInLeft" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>
								<a data-toggle="modal" data-target="#balvikasThev"><h2>Balvikas Thev Yojna(Masik)</h2></a>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
	<div className="modat-content">
  <div className="modal fade" id="alpaMudatModel">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Alpa Mudat Thev</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/alpaMudat.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>30 Days to 45 Days</p>
          						</div>
          						<div className="col-md-4">
          							<p>3%</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>46 Days to 90 Days</p>
          						</div>
          						<div className="col-md-4">
          							<p>4%</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>91 Days to 180 Days</p>
          						</div>
          						<div className="col-md-4">
          							<p>5%</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>181 Days to 365 Days</p>
          						</div>
          						<div className="col-md-4">
          							<p>8%</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>13 Month to 24 Month</p>
          						</div>
          						<div className="col-md-4">
          							<p>8.50%</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>25 Month to 36 Month</p>
          						</div>
          						<div className="col-md-4">
          							<p>9%</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>37 Month to Onward</p>
          						</div>
          						<div className="col-md-4">
          							<p>8.50%</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>401 Divsansathi Samanya Nagrikansathi</p>
          						</div>
          						<div className="col-md-4">
          							<p>9.50%</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>401 Divsansathi Jeshta Nagrikansathi</p>
          						</div>
          						<div className="col-md-4">
          							<p>10%</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-8 thev-info">
          							<p>30 Days to 45 Days</p>
          						</div>
          						<div className="col-md-4">
          							<p>3%</p>
          						</div>
          					</div>
          				</li>

          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
       
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>




<div className="modal fade" id="sankalThev">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Sankalp Thev</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/sankalp.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Thev Rs.</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">4 Year(8%)</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">5 Year(9%)</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>1,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1,37,279</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1,56,051</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>3,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>4,11,836</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>4,68,153</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>5,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>6,86,393</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>7,80,255</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>10,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>13,72,786</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>15,60,509</p>
          						</div>
          					</div>
          				</li>

          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>





<div className="modal fade" id="lokmangalThev">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Lok Mangal Thev</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/lokMangal.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Pratimaha Bhara.</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Year 1</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Year 2</p>
          						</div>

          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Year 3</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>100</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,230</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>2,540</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>3,950</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>500</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>6,150</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>12,700</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>19,750</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>1000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>12,300</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>25,400</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>39,500</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>5,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>61,500</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,27,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,97,500</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>10,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,23,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>2,54,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>3,95,000</p>
          						</div>
          					</div>
          				</li>


          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>




<div className="modal fade" id="lakhlaxmiThev">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Lakhlaxmi Thev Yojna</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/lakhlaxmi.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Masik Thev (Rs.)</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Mahine</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Vyajdar</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Milva</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>1,360</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>60</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>8%</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,00,000</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          						<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>1,840</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>48</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>7.5%</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,00,000</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>2,550</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>36</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>7%</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,00,000</p>
          						</div>
          					</div>
          				</li>

          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>








<div className="modal fade" id="kalyankari">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Kalyankari Thev Yojna</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	<div className="modal-tc">
        		<p>Rs. 100/- Va Tyapatit Bhara Va 5 Varshananter Milva.<br />
        			Vyajdar 8.5%
        		</p>
        	</div>
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/kalyankari.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Prati Maha Bharna</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Mudatinanter</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Prati Maha Bharna</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p className="para-heading">Mudatinanter</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>100</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>7,485</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>74,852</p>
          						</div>
          					</div>
          				</li>

          				<li className="even">
          						<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>200</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>14,970</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,500</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,12,278</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>300</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>22,456</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>2,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,49,704</p>
          						</div>
          					</div>
          				</li>


          				<li className="even">
          						<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>400</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>29,941</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>2,500</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,87,130</p>
          						</div>
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>500</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>37,426</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>5,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>3,74,260</p>
          						</div>
          					</div>
          				</li>

          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>





<div className="modal fade" id="masikmudat">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Masik Thev Yojna</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	<div className="modal-tc">
        		<p>Pudhil Taktyapramane vyaj darmaha thevi darasarkhe dile jail kinva bachat khatyavar jama kele jail.<br />
        			Kalavadhi: 13 te 24 mahine - 8% va Jeshta Nagrik-8.50%<br />
        			25 te 36 mahine -  8.50% va Jeshta Nagrik-9%
        		</p>
        	</div>
          <div className="row">
          
          	<div className="col-md-12">
          		<div className="yojna-text">
          			<h6>13 Te 24 Mahine</h6>
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Rupaye</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Samanya-8%</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Jeshta-8.50%</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>10,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>67</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>71</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          						<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>25,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>167</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>171</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>50,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>333</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>354</p>
          						</div>
          					
          					</div>
          				</li>


          				<li className="even">
          						<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>1,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>667</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>708</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>5,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>3,333</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>3,542</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>10,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>6,667</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>7,083</p>
          						</div>
          					
          					</div>
          				</li>

          				
          			</ul>
          		</div>
          	</div>
          </div>



           <div className="row">
          
          	<div className="col-md-12">
          		<div className="yojna-text">
          			<h6>25 Te 36 Mahine</h6>
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Rupaye</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Samanya-8.50%</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Jeshta-9%</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>10,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>71</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>75</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          						<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>25,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>177</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>188</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>50,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>354</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>375</p>
          						</div>
          					
          					</div>
          				</li>


          				<li className="even">
          						<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>1,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>708</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>750</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>5,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>3,542</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>3,750</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>10,00,000</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>7,083</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>7,500</p>
          						</div>
          					
          					</div>
          				</li>

          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>


<div className="modal fade" id="vikasthev">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Vikas Thev Yojna</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	<div className="modal-tc">
        		<p>54 te 90 mahinyasathi guntava a didpat te duppat milva.(Vyajdar 9.5%)
        		</p>
        	</div>
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/vikas.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">54 Mahine</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">90 Mahine</p>
          						</div>
          						
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-3 thev-info">
          							<p>1,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>1,500</p>
          						</div>


          						<div className="col-md-3 thev-info">
          							<p>1,000</p>
          						</div>
          						<div className="col-md-3 thev-info">
          							<p>2,000</p>
          						</div>
          						
          					</div>
          				</li>

          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>




<div className="modal fade" id="retirement">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Retirement Thev</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	<div className="modal-tc">
        		<p>Retirement Thev Yojna
        			<br />
        			1 varsha te 25 varsha darmaha guntava av manya chart pramane mudatianti Rs. 5,00,000/- Milva.
        			
        		</p>
        	</div>
          <div className="row">
          
          	<div className="col-md-12">
          		<div className="yojna-text">
          			
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Guntavnuk Varsha</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Masik Guntavnuk</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p className="para-heading">Milnari Rakkam</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>25</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>832</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>24</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>885</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

						<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>23</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>942</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>22</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1006</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>21</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1077</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>20</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1156</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>19</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1244</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>18</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1344</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>17</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1457</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>16</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1585</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>15</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1733</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>14</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>1904</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>


          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>13</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>2105</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>12</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>2342</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>11</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>2625</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>10</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>2970</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>9</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>3397</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>8</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>3937</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>7</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>4640</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>6</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>5586</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>5</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>6926</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>4</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>8955</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>3</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>12365</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>2</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>19231</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>


          				<li className="odd">
          					<div className="row">
          						<div className="col-md-4 thev-info">
          							<p>1</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>39937</p>
          						</div>
          						<div className="col-md-4 thev-info">
          							<p>500000</p>
          						</div>
          					
          					</div>
          				</li>





          				
          			</ul>
          		</div>
          	</div>
          </div>



           
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>







<div className="modal fade" id="ucchashikshan">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Ucchashikshan Thev</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	<div className="modal-tc">
        		<p>Ucchashikshan Thev<br />
        			Masik Rs. 1077 guntava 252 mahinyananter Rs. 5,00,000/- Milva. Sadar Thevit Rs. 5 Lakh va tya pattit rakkam milavnyakarta bhag gheta yeil.
        		</p>
        	</div>
          <div className="row">
          
          	<div className="col-md-12">
          		<div className="yojna-text">
          			
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Masik Bharna</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Milnari Rakkam</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>1077</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>500000</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="even">
          						<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>2154</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>1000000</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>3231</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>1500000</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="even">
          						<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>4308</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>2000000</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          						<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>5385</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>2500000</p>
          						</div>
          						
          					
          					</div>
          				</li>
          				
          			</ul>
          		</div>
          	</div>
          </div>



           
        </div>
        
      
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>



<div className="modal fade" id="subhamangal">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Shubhmangal Thev</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	<div className="modal-tc">
        		<p>Shubhamangal Thev<br />
        			Masik Rs. 1345 guntava 18 varshyananter Rs. 5,00,000/- Milva. Sadar Thevit Rs. 5 Lakh va tya pattit rakkam milavnyakarta bhag gheta yeil.
        		</p>
        	</div>
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/marriage.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Masik Bharna</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Milnari Rakkam</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>1345</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>500000</p>
          						</div>
          						
          					
          					</div>
          				</li>


          				<li className="even">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>2690</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>1000000</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>4035</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>1500000</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="even">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>5380</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>2000000</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>6725</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>2500000</p>
          						</div>
          						
          					
          					</div>
          				</li>



          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
       
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>



<div className="modal fade" id="lakhlaxmiThevMasik">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Lakhlaxmi Thev Yojna</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	<div className="modal-tc">
        		<p>Mudatinanter Milnari Rakkam Rs. 1,00,000/-
        		</p>
        	</div>
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/lakhlaxmiMasik.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Bharna Karavyachi Rakkam</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Vyajdar</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>2410</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>3 Varsha-10%</p>
          						</div>
          						
          					
          					</div>
          				</li>


          				<li className="even">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>1720</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>4 Varsha-10.5%</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				<li className="odd">
          				<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>1305</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p>5 Varsha-11%</p>
          						</div>
          						
          					
          					</div>
          				</li>

          				
          			</ul>
          		</div>
          	</div>
          </div>
        </div>
        
       
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>








<div className="modal fade" id="aavartThev">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Aavart Thev Yojna</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	
          <div className="row">
          	<div className="col-md-4">
          		<div className="yojna-img">
          			<img src="../../assets/images/aavart.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-8">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Bharna Karavyachi Rakkam</p>
          						</div>
          						<div className="col-md-6 thev-info">
          							<p className="para-heading">Mudati Nanter Milnari Rakkam</p>
          						</div>

          						<div className="col-md-6 thev-info">
          							
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '2px solid #d7efff'}}>
          							<p className="para-heading">1 Varsha</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '2px solid #d7efff'}}>
          							<p className="para-heading">2 Varsha</p>
          						</div>
          						
          					
          					</div>
          				</li>


          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>100</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>1249</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>2600</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>200</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>2498</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>5200</p>
          						</div>

          						
          					
          					</div>
          				</li>


          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>300</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>3746</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>7800</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>400</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>4995</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>10400</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>500</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>6244</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>13000</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>600</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>7493</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>15600</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>700</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>8741</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>18200</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>800</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>9990</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>20800</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>900</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>11239</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>23400</p>
          						</div>

          						
          					
          					</div>
          				</li>

          				<li className="odd">
          					<div className="row">
          						<div className="col-md-6 thev-info">
          							<p>1000</p>
          						</div>
          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>12488</p>
          						</div>

          						<div className="col-md-3 thev-info" style={{borderTop: '1px solid #d7efff'}}>
          							<p>26000</p>
          						</div>

          						
          					
          					</div>
          				</li>


          			</ul>
          		</div>
          	</div>

          </div>
        </div>

        	<div className="modal-tc">
        		<p>Varil Thev Yojnet Sabhasadane swata shakhet yeun thev rakkam bharne aavshyak ahe.</p>
        		<p>Dainandin vasuli pratinidhi marfat (agency dware) thev rakkam hafta jama karta yenar nahi.</p>
        	</div>
        
       
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>





<div className="modal fade" id="balvikasThev">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
      
       
        <div className="modal-header">
          <h4 className="modal-title">Balvikas Thev Yojna</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        
      
        <div className="modal-body">
        	
          <div className="row">
          	<div className="col-md-3">
          		<div className="yojna-img">
          			<img src="../../assets/images/balvikas.jpg" className="img-fluid d-block" />
          		</div>
          	</div>

          	<div className="col-md-9">
          		<div className="yojna-text">
          			<ul>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-2 thev-info">
          							<p className="para-heading">Vay</p>
          						</div>
          						<div className="col-md-2 thev-info">
          							<p className="para-heading">1 te 11 Mahine</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p className="para-heading">1 Varsha</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p className="para-heading">2 Varsha</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p className="para-heading">3 Varsha</p>
          						</div>

          						<div className="col-md-1 thev-info" style={{padding: 0}}>
          							<p className="para-heading" style={{fontSize: '16px'}}>4 Varsha</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p className="para-heading" style={{fontSize: '16px'}}>5 Varsha</p>
          						</div>
          					
          					</div>
          				</li>


          				<li className="odd">
          					<div className="row">
          						<div className="col-md-2 thev-info" style={{padding: 0}}>
          							<p>Mahina</p>
          						</div>
          						<div className="col-md-2 thev-info">
          							<p>216</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>204</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>192</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>180</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p>168</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p>156</p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-2 thev-info" style={{padding: 0}}>
          							<p>Bharavayachi Rakkam</p>
          						</div>
          						<div className="col-md-2 thev-info">
          							<p>20,200</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>22,100</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>24,100</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>26,400</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p>28,800</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p>31,500</p>
          						</div>
          					
          					</div>
          				</li>


          				<li className="odd">
          					<div className="row">
          						<div className="col-md-2 thev-info" style={{padding: 0}}>
          							<p>Vay</p>
          						</div>
          						<div className="col-md-2 thev-info">
          							<p>6 Varsha</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>7 Varsha</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>8 Varsha</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>9 Varsha</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p>10 Varsh</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p></p>
          						</div>
          					
          					</div>
          				</li>

          				<li className="even">
          					<div className="row">
          						<div className="col-md-2 thev-info" style={{padding: 0}}>
          							<p>Mahine</p>
          						</div>
          						<div className="col-md-2 thev-info">
          							<p>144</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>132</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>120</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>108</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p>96</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p></p>
          						</div>
          					
          					</div>
          				</li>

          					<li className="odd">
          					<div className="row">
          						<div className="col-md-2 thev-info" style={{padding: 0}}>
          							<p>Bharavayachi Rakkam</p>
          						</div>
          						<div className="col-md-2 thev-info">
          							<p>34,400</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>37,600</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>41,100</p>
          						</div>

          						<div className="col-md-2 thev-info">
          							<p>44,900</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p>49,100</p>
          						</div>

          						<div className="col-md-1 thev-info">
          							<p></p>
          						</div>
          					
          					</div>
          				</li>





          			</ul>
          		</div>
          	</div>

          </div>
        </div>

        
       
        <div className="modal-footer">
          <button type="button" className="modal-btn" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>


</div>

	</React.Fragment>
	
    );  
  }
}
  
  export default DepositScheme;