import React,{Component} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history'
import Home from '../ReactViews/Home/Home';
import Header from '../ReactViews/Header/Header';
import Footer from '../ReactViews/Footer/Footer';
import Branch from '../ReactViews/Branch/Branch';
import LonScheme from '../ReactViews/LoanScheme/LonScheme';
import Service from '../ReactViews/Service/Service';
import AnnualReport from '../ReactViews/AnnualReport/AnnualReport';
import DepositScheme from '../ReactViews/DepositScheme/DepositScheme';
import Gallery from '../ReactViews/Gallery/Gallery';
import Contact from '../ReactViews/Contact/Contact';
import Download from '../ReactViews/Download/Download';

import AboutUs from '../ReactViews/About/AboutUs';
import Vision from '../ReactViews/About/Vision';
import Manogat from '../ReactViews/About/Manogat';
import OfficerList from '../ReactViews/OfficerList/OfficerList'

import TmpDepositScheme from '../ReactViews/DepositScheme/TempDeposit';
import TmpLoanScheme from '../ReactViews/LoanScheme/TmpLoanScheme';

import FounderDirector from '../ReactViews/Management/FounderDirector';
import BoardDirector from '../ReactViews/Management/BoardDirector';
import CommitteeList from '../ReactViews/Management/CommitteeList';
import BranchConsult from '../ReactViews/Management/BranchConsult';

import Career from '../ReactViews/Career/Career';
import NewsProgram from '../ReactViews/NewsProgram/NewsProgram'
import FinancialStatus from '../ReactViews/Home/FinancialStatus';
import InterestRate from '../ReactViews/DepositScheme/InterestRate';


const history = createBrowserHistory();
class RouterConfig extends Component {
render(){
  return (
      <BrowserRouter basename={'/ambika_ui'}  history={history}>
         <Header /> 
            <Switch> 
              <Route path="/home" render={props => <Home {...props} />} />  
              <Route path="/branch" render={props => <Branch {...props} />} />  
              <Route path="/service" render={props => <Service {...props} />} />  
              <Route path="/annual-report" render={props => <AnnualReport {...props} />} />  
              {/* <Route path="/deposit-scheme" render={props => <DepositScheme {...props} />} />   */}
              <Route path="/deposit-scheme" render={props => <TmpDepositScheme {...props} />} />  

              {/* <Route path="/loan-scheme" render={props => <LonScheme {...props} />} />   */}
              <Route path="/loan-scheme" render={props => <TmpLoanScheme {...props} />} />  

              
              
              <Route path="/gallery" render={props => <Gallery {...props} />} />  
              <Route path="/contact" render={props => <Contact {...props} />} />  
              <Route path="/download" render={props => <Download {...props} />} />  
              
              <Route path="/about-us" render={props => <AboutUs {...props} />} />  
              <Route path="/vision" render={props => <Vision {...props} />} />  
              <Route path="/occult" render={props => <Manogat {...props} />} />  

              <Route path="/founder-director" render={props => <FounderDirector {...props} />} />  
              <Route path="/board-of-director" render={props => <BoardDirector {...props} />} />  
              <Route path="/committee" render={props => <CommitteeList {...props} />} />  
              <Route path="/branch-consultant-committee" render={props => <BranchConsult {...props} />} />  

              <Route path="/career" render={props => <Career {...props} />} />  
              <Route path="/news" render={props => <NewsProgram {...props} />} />  
              <Route path="/officer-list" render={props => <OfficerList {...props} />} />  

              <Route path="/financial-condition" render={props => <FinancialStatus {...props} />} />  
              <Route path="/interest-rate" render={props => <InterestRate {...props} />} />  

              

              

             <Redirect from="/" to="/home" />}
           </Switch>
         <Footer />
        </BrowserRouter>
    );  
  }
}

export default RouterConfig;