import React,{Component} from "react";
import {_getCareerList} from '../../../actions/simpleActions';
import PropTypes from 'prop-types';
import { withRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPreivew from '../sub_parts/LoadingPreivew';
import ApplyPosition from './ApplyPosition';

class Career extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      careerData:[],
      loadingFlag:false,
      loadingData:"loading data...",
      openApplyModal:false,
      careerInfo:[],


    }
    this.openAapplyModalFunc    = this.openAapplyModalFunc.bind(this);

    
  }
  
  openAapplyModalFunc(evt,data){
      this.setState({careerInfo:data,openApplyModal:true});
    //   $('#show_buyer_feedback').trigger("click");
      document.getElementById("show_buyer_feedback").click(); 

  }

  _getCareerList = () => {
    let that= this;
    this.setState({loadingFlag:true,loadingData:'Loading data.....'});

    this.props._getCareerList().then(response => { 
        if(response.data.success){
          that.setState({loadingFlag:false,careerData:response.data.data,loadingData:''});
        }else{
           that.setState({loadingFlag:false,careerData:[],loadingData:'No records found!'});
        }
    }).catch(function (error) {
      console.log(error)
          that.setState({loadingFlag:false,loadingData:'Error in displaying records',careerData:[]});
     });
  }

  componentDidMount(){
    this._getCareerList();
  }


  _setResp(data){
    if(data){
    const result =  data.split(',').map((x,i)=> <li key={i}>{x}</li>);
    return result;
    }else{
      return null;
    }
  }

render(){
  const { careerData } = this.state;
  
  return (
   
<div className="career">
 <div className="container">
     {careerData && careerData.length > 0 && careerData.map((career,index)=>(
   <div className="career-block" key={index}>
     <div className="career-option">
       <div className="career-heading">
         <h1>पद  - {career.position ? career.position : ''}</h1>

       </div>

       <div className="career-text">
         <p><span>पद   :</span> {career.position ? career.position : ''}</p>

         {/* <p><span>Industry Type  :</span> Patsanstha , Banking</p>

         <p><span>Functional Area  :</span> Sales, Retail, Business Development</p> */}

         <p><span>रोजगाराचा प्रकार  :</span> {career.employment_type ? career.employment_type : ''}</p>

         <p><span>शिक्षण   :</span>{career.qualification ? career.qualification : ''}</p>

         <p><span>अनुभव   :</span> {career.experience ? career.experience : ''}</p>


       </div>

       <div className="responsibility-block">
        <h4>जबाबदारी :-</h4>
         <ul>
             {this._setResp(career.responsibility)}

         </ul>
       </div>
       <div className="btn-section">
          <a href="javascript:void(0);" className="apply-responsibility" onClick={(evt)=>this.openAapplyModalFunc(evt,career)}>जतन करा</a>
       </div>
     </div>
   </div>))}


    {this.state.loadingFlag &&
    <div className="row loadingData">
    <LoadingPreivew loading={this.state.loadingFlag ? true : false}/>
    <h5 className="text-danger text-center"> {this.state.loadingData} </h5>
    </div>}

 </div> 

<input type="hidden" id="show_buyer_feedback" data-controls-modal="buyer_feedback" data-toggle="modal" data-target="#buyer_feedback" />

 <ApplyPosition openApplyModal={this.state.openApplyModal} careerInfo={this.state.careerInfo} />

</div>
 

    );  
  }
}
  
Career.propTypes = {
  _getCareerList: PropTypes.func.isRequired
}

export default withRouter(connect(null, {_getCareerList})(Career));


